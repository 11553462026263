import { Box } from '@mui/material';

function BasePage({ children }: any) {
  return (
      <>
        <Box sx={{
          padding: 1,
          paddingBottom: 2,
          borderRadius: '10px',
          minHeight: '100%'
        }}>
        {children}
        </Box>
      </>
  );
}

export default BasePage;
