import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { MyProfile } from './myProfile.types';
import myProfileService from '../../services/myProfile/myProfile.service';

export type MyProfilePageState = {
  language: string,
  myName: string,
};

const initialState: MyProfilePageState = {
  language: '',
  myName: '',
};

export const loadProfile = createAsyncThunk<MyProfile>(
'matrix/loadProfile',
async (_, { rejectWithValue }) => {
  try {
    const response = await myProfileService.getOne();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while get company');
  }
});

const myProfileSlice = createSlice<MyProfilePageState, SliceCaseReducers<MyProfilePageState>>({
  name: 'my-profile',
  initialState,
  reducers: {
    setInputLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setInputName: (state, action: PayloadAction<string>) => {
      state.myName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProfile.fulfilled, (state: MyProfilePageState, action: PayloadAction<MyProfile>) => {
        if (state.myName !== action.payload.name) { // чтобы не мигало
          state.myName = action.payload.name;
        }
      })
  },
});

const myProfileReducer = myProfileSlice.reducer;

export const {
  setInputLanguage,
  setInputName,
} = myProfileSlice.actions;

export default myProfileReducer;
