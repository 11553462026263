import { Box, Drawer, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import AuthService from '../services/auth/auth.service';
import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import SpecialistsPage from './specialists/SpecialistsPage';
import SchedulePage from './schedule/SchedulePage';
import PricelistPage from './pricelist/PricelistPage';
import SettingsPage from './settings/SettingsPage';
import BasePage from '../components/BasePage';
import ClientsPage from './clients/ClientsPage';
import RoomsPage from './rooms/RoomsPage';
import ServicesPage from './services/ServicesPage';
import MatrixPage from './matrix/MatrixPage';
import StatisticsPage from './statistics/StatisticsPage';
import { setBackPage, setCurrentPage, setStudioId } from './applicationPage.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import UsersPage from './users/UsersPage';
import StudiosPage from './studios/StudiosPage';
import MyProfilePage from './myProfile/MyProfilePage';
import SupportPage from './support/SupportPage';
import CompanyPage from './company/CompanyPage';
import { ClientCardPage } from './clientCard/ClientCardPage';
import SubscriptionsPage from './subscriptions/SubscriptionsPage';
import { fetchStudiosDict } from '../store/dictionaries.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { Studio } from '../services/studios/studios.types';
import { localStore } from '../store/localStore';
import { InitialValues } from '../types/types';
import ClientGroupsPage from './clientGroups/ClientGroupsPage';

const DEFAULT_PAGE = 'schedule';

const ApplicationPage = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, { }, AnyAction> = useDispatch();
  const { currentPage, backPage, studioId } = useSelector((state: RootState) => state.app);
  const { studios } = useSelector((state: RootState) => state.dictionaries);

  useEffect(() => {
    if (currentPage === '') {
      const page = window.location.hash.substring(1) || DEFAULT_PAGE;
      dispatch(setCurrentPage(page));

      navigate(`#${page}`);
    } else {
      navigate(`#${currentPage}`);
    }

    if (!AuthService.checkAuth()) {
      navigate('/');
    }
  }, [currentPage, navigate, dispatch]);

  useEffect(() => {
    dispatch(fetchStudiosDict());
  }, [dispatch]);

  useEffect(() => {
    const studioIdFromLocalStorage = localStore.loadStudioId();

    if (studioIdFromLocalStorage && studios.find((studio) => studio.id === studioIdFromLocalStorage)) {
      dispatch(setStudioId(studioIdFromLocalStorage));
    } else if (studios[0]) {
      dispatch(setStudioId(studios[0].id));
    } else {
      dispatch(setStudioId(InitialValues.UNKNOWN_ID));
    }
  }, [dispatch, studios]);

  const handleMenuClick = (page: string) => {
    dispatch(setCurrentPage(page));
    dispatch(setBackPage(null)); // back page only for internal pages
  };

  const onStudioChange = async(event: SelectChangeEvent<number>) => {
    const selectedStudioId = Number(event.target.value);
    localStore.saveStudioId(selectedStudioId);
    dispatch(setStudioId(selectedStudioId));
  };

  const renderPage = () => {
    const pageMap: Record<string, any> = {
      clients: <ClientsPage />,
      subscriptions: <SubscriptionsPage />,
      'client-card': <ClientCardPage />,
      matrix: <MatrixPage />,
      pricelist: <PricelistPage />,
      rooms: <RoomsPage />,
      schedule: <SchedulePage />,
      services: <ServicesPage />,
      specialists: <SpecialistsPage />,
      statistics: <StatisticsPage />,
      studios: <StudiosPage />,
      settings: <SettingsPage />,
      users: <UsersPage />,
      'my-profile': <MyProfilePage />,
      company: <CompanyPage />,
      support: <SupportPage />,
      'client-groups': <ClientGroupsPage />,
    };

    return <BasePage backPage={backPage}>{pageMap[currentPage]}</BasePage>;
  };

  return (
    <Box sx={{
      minHeight: '100vh',
      backgroundColor: "#F5F5F5",
    }}>
      <Drawer
        variant="permanent"
        PaperProps={{
          style: {
            borderRadius: '0px 25px 25px 0px',
            border: '0',
            backgroundColor: '#84B921',
          }
        }
      }
        sx={{
          flexShrink: 0,
          border: 'none',
        }}
      >
        <Select
            placeholder={"Studio"}
            value={studioId}
            onChange={onStudioChange}
            sx={{
              backgroundColor: 'white',
              minWidth: '130px',
              margin: '20px',
              borderRadius: '15px',
              "& .MuiSelect-icon": {
                fontSize: '200%',
              },
            }}
        >
          {studios.map((studio: Studio) => (
              <MenuItem key={studio.id} value={studio.id}>{studio.name}</MenuItem>
          ))}
        </Select>

        <List sx={{
          width: 240,
          backgroundColor: '#84b921',
          color: 'white',
          fontWeight: '600',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          }}
        >
          <div>
            <ListItemButton onClick={() => handleMenuClick('schedule')} classes={{ root: 'main-menu main-menu-schedule', selected: 'menu-item-selected' }} selected={currentPage === 'schedule'}>
              <ListItemText primary="Schedule" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('matrix')} classes={{ root: 'main-menu main-menu-matrix', selected: 'menu-item-selected' }} selected={currentPage === 'matrix'}>
              <ListItemText primary="Worktime matrix" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('pricelist')} classes={{ root: 'main-menu main-menu-pricelist', selected: 'menu-item-selected' }} selected={currentPage === 'pricelist'}>
              <ListItemText primary="Pricelist" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('specialists')} classes={{ root: 'main-menu main-menu-specialists', selected: 'menu-item-selected' }} selected={currentPage === 'specialists'}>
              <ListItemText primary="Specialists" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('rooms')} classes={{ root: 'main-menu main-menu-rooms', selected: 'menu-item-selected' }} selected={currentPage === 'rooms'}>
              <ListItemText primary="Rooms" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('clients')} classes={{ root: 'main-menu main-menu-clients', selected: 'menu-item-selected' }} selected={currentPage === 'clients'}>
              <ListItemText primary="Clients" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('client-groups')} classes={{ root: 'main-menu main-menu-client-groups', selected: 'menu-item-selected' }} selected={currentPage === 'client-groups'}>
              <ListItemText primary="Client groups" />
            </ListItemButton>
          </div>
          <div>
            <ListItemButton onClick={() => handleMenuClick('subscriptions')} classes={{ root: 'main-menu main-menu-subscriptions', selected: 'menu-item-selected' }} selected={currentPage === 'subscriptions'}>
              <ListItemText primary="Subscriptions" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('studios')} classes={{ root: 'main-menu main-menu-studios', selected: 'menu-item-selected' }} selected={currentPage === 'studios'}>
              <ListItemText primary="Studios" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('services')} classes={{ root: 'main-menu main-menu-services', selected: 'menu-item-selected' }} selected={currentPage === 'services'}>
              <ListItemText primary="Services" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('statistics')} classes={{ root: 'main-menu main-menu-statistics', selected: 'menu-item-selected' }} selected={currentPage === 'statistics'}>
              <ListItemText primary="Statistics" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('my-profile')} classes={{ root: 'main-menu main-menu-my-profile', selected: 'menu-item-selected' }} selected={currentPage === 'my-profile'}>
              <ListItemText primary="My profile" />
            </ListItemButton>
            <ListItemButton onClick={() => handleMenuClick('company')} classes={{ root: 'main-menu main-menu-company', selected: 'menu-item-selected' }} selected={currentPage === 'company'}>
              <ListItemText primary="My company" />
            </ListItemButton>

            {/*<ListItemButton onClick={() => handleMenuClick('users')} classes={{ root: 'main-menu main-menu-users', selected: 'menu-item-selected' }} selected={currentPage === 'users'}>*/}
            {/*  <ListItemText primary="Users" />*/}
            {/*</ListItemButton>*/}
          </div>
          <div>
            <ListItemButton onClick={() => handleMenuClick('support')} classes={{ root: 'main-menu main-menu-support', selected: 'menu-item-selected' }} selected={currentPage === 'support'}>
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton component={Link} to="/logout" classes={{ root: 'main-menu main-menu-exit' }} >
              <ListItemText primary="Logout" />
            </ListItemButton>
          </div>
        </List>
      </Drawer>

      <Box sx={{
        backgroundColor: "#F5F5F5",
        flex: 1,
        width: 'calc(100% - 240px - 2*15px)',
        marginLeft: '240px',
        padding: '15px',
      }}>
        <Box sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          minHeight: 'calc(100vh - 30px)',
        }}>
          {renderPage()}
        </Box>
      </Box>
    </Box>
  );
}

export default ApplicationPage;
