import { Box, Container } from '@mui/material';
import React from 'react';
import logo from '../../assets/images/main-logo.png';
import ForgotPasswordRequest from './components/ForgotPasswordRequest';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import ForgotPasswordConfirm from './components/ForgotPasswordConfirm';

const ForgotPasswordPage = () => {
  const {
    step
  } = useSelector((state: RootState) => state.forgotPassword);

  return (
    <Container sx={{
      backgroundColor: "#84B921",
      height: '100vh', // Занимает 100% высоты экрана
      display: 'flex',
      justifyContent: 'center', // Центрируем по горизонтали
      alignItems: 'center', // Центрируем по вертикали
    }} maxWidth={false}>
      <Box sx={{
        width: 720,
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '32px'
      }}>
        <Box sx={{
          textAlign: 'center'
        }}>
          <img src={logo} alt="logo" width={234}/>
        </Box>

        <Box sx={{
          fontWeight: 'bold',
          fontSize: '24px',
          color: '#434343',
          padding: '24px 0px'
          }}
        >Forgot password</Box>

        {step === 1
          ? <ForgotPasswordRequest />
          : <ForgotPasswordConfirm />
        }
      </Box>
    </Container>
  );
}

export default ForgotPasswordPage;
