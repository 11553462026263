import { configureStore } from '@reduxjs/toolkit';
import specialistsPageReducer from '../pages/specialists/specialistsPage.slice';
import dialogInfoReducer from '../components/dialog/dialogInfo.slice';
import servicesReducer from '../pages/services/servicesPage.slice';
import roomsReducer from '../pages/rooms/roomsPage.slice';
import scheduleReducer from '../pages/schedule/schedulePage.slice';
import loginReducer from '../pages/login/loginPage.slice';
import applicationPageReducer from '../pages/applicationPage.slice';
import matrixReducer from '../pages/matrix/matrixPage.slice';
import pricelistReducer from '../pages/pricelist/pricelistPage.slice';
import dictionariesReducer from './dictionaries.slice';
import usersReducer from '../pages/users/usersPage.slice';
import studiosReducer from '../pages/studios/studiosPage.slice';
import supportReducer from '../pages/support/supportPage.slice';
import clientsReducer from '../pages/clients/clientsPage.slice';
import companyReducer from '../pages/company/company.slice';
import clientCardReducer from '../pages/clientCard/clientCardPage.slice';
import statisticsReducer from '../pages/statistics/statisticsPage.slice';
import forgotPasswordReducer from '../pages/forgotPassword/forgotPassword.slice';
import subscriptionsReducer from '../pages/subscriptions/subscriptionsPage.slice';
import bookingAddReducer from '../pages/booking/bookingAddDialog.slice';
import myProfileReducer from '../pages/myProfile/myProfile.slice';
import clientGroupsReducer from '../pages/clientGroups/clientGroupsPage.slice';

const store = configureStore({
  reducer: {
    specialists: specialistsPageReducer,
    services: servicesReducer,
    rooms: roomsReducer,
    studios: studiosReducer,
    dialogInfo: dialogInfoReducer,
    schedule: scheduleReducer,
    matrix: matrixReducer,
    login: loginReducer,
    app: applicationPageReducer,
    pricelist: pricelistReducer,
    dictionaries: dictionariesReducer,
    users: usersReducer,
    myProfile: myProfileReducer,
    support: supportReducer,
    clients: clientsReducer,
    clientCard: clientCardReducer,
    company: companyReducer,
    statistics: statisticsReducer,
    subscriptions: subscriptionsReducer,
    forgotPassword: forgotPasswordReducer,
    bookingAdd: bookingAddReducer,
    clientGroups: clientGroupsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
