import CustomNoRowsOverlay from '../../../components/grid/CustomNoRowsOverlay';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { Booking } from '../../../services/bookings/bookings.types';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getServicesWithDeleted } from '../../../store/dictionaries.selectors';

const BookingsGrid = () => {
  const { bookings} = useSelector((state: RootState) => state.clientCard);
  const { specialists, rooms } = useSelector((state: RootState) => state.dictionaries);
  const { studioId } = useSelector((state: RootState) => state.app);
  const servicesWithDeleted = useSelector(getServicesWithDeleted);

  const columnsBookings: GridColDef[] = [{
    field: 'id',
  },{
    field: 'serviceId',
    headerName: 'Service',
    width: 200,
    renderCell: (params: GridRenderCellParams<Booking>) => {
      return servicesWithDeleted.find((service) => service.id === params.value)?.name || '';
    }
  },{
    field: 'specialistId',
    headerName: 'Specialist',
    width: 200,
    renderCell: (params: GridRenderCellParams<Booking>) => {
      return specialists[studioId].find((specialist) => specialist.id === params.value)?.name || '';
    }
  },{
    field: 'roomId',
    headerName: 'Room',
    width: 200,
    renderCell: (params: GridRenderCellParams<Booking>) => {
      return rooms.find((room) => room.id === params.value)?.name || '';
    }
  },{
    field: 'date',
    headerName: 'Date',
    width: 200,
    renderCell: (params: GridRenderCellParams<Booking>) => {
      return DateTime.fromISO(params.row.date).toFormat('dd.MM.yyyy') + ' ' + DateTime.fromISO(params.row.start).toFormat('hh.mm')
          + ' - ' + DateTime.fromISO(params.row.end).toFormat('hh.mm');
    }
  }];

  return (
    <>
      <DataGrid
        sx={{
          '&, [class^=MuiDataGrid-root]': { border: 'none' },
          height: 'calc(100vh - 375px)',
          width: '100%'
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        slots={{ noRowsOverlay: () => <CustomNoRowsOverlay text={"No bookings"} /> }}
        columns={columnsBookings}
        rows={bookings}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </>
  );
};

export default BookingsGrid;
