import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { User } from '../../services/users/users.types';
import UsersService from '../../services/users/users.service';

type DialogAddEditAction = {
  mode: 'add',
} | {
  mode: 'edit',
  id: number,
  name: string,
  status: string,
  role: string,
};

export type UsersPageState = {
  data: User[],
  dialogs: {
    addEdit: {
      open: boolean;
      mode: 'add' | 'edit',
      success: boolean;
      inputs: {
        id: number | null,
        email: string,
        name: string,
        status: string,
        role: string,
      }
    },
    delete: {
      open: boolean;
      deletingId: number | null;
    },
  },
};

const initialState: UsersPageState = {
  data: [],
  dialogs: {
    addEdit: {
      open: false,
      mode: 'add',
      success: false,
      inputs: {
        id: null,
        email: '',
        name: '',
        status: '',
        role: '',
      }
    },
    delete: {
      open: false,
      deletingId: null,
    },
  },
};

export const fetchUsers = createAsyncThunk<User[]>(
'services/fetchUsers',
async (_, { rejectWithValue }) => {
  try {
    const response = await UsersService.getList();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch users');
  }
});

const usersPageSlice = createSlice<UsersPageState, SliceCaseReducers<UsersPageState>>({
  name: 'services',
  initialState,
  reducers: {
    closeUserDialogAddEdit: (state) => {
      state.dialogs.addEdit.open = false;
    },
    openUserDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.dialogs.addEdit.open = true;
      state.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.dialogs.addEdit.inputs, initialState.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.dialogs.addEdit.inputs, action.payload);
      }
    },
    closeUserDialogDelete: (state) => {
      state.dialogs.delete.open = false;
    },
    openUserDialogDelete: (state, action: PayloadAction<number>) => {
      state.dialogs.delete.deletingId = action.payload;
      state.dialogs.delete.open = true;
    },
    setInputId: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.id = action.payload;
    },
    setInputName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.name = action.payload;
    },
    setInputStatus: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.status = action.payload;
    },
    setInputRole: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.role = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state: UsersPageState, action) => {
        state.data.length = 0;
        state.data.push(...action.payload);
      });
  },
});

const usersReducer = usersPageSlice.reducer;

export const {
  setInputId,
  setInputName,
  setInputStatus,
  setInputRole,
  openUserDialogAddEdit,
  closeUserDialogAddEdit,
  closeUserDialogDelete,
  openUserDialogDelete,
} = usersPageSlice.actions;

export default usersReducer;
