import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import {
  openCloseDialogSalesReport,
  setInputSalesDateFrom,
  setInputSalesDateTo,
} from '../statisticsPage.slice';
import { fetchServicesDict } from '../../../store/dictionaries.slice';
import statisticsService from '../../../services/statistics/statistics.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';

export const StatisticsSalesReportDialog = () => {
  const { sales: { open: openSales, fields: {
    dateFrom: sales1_dateFrom,
    dateTo: sales1_dateTo,
  } } } = useSelector((state: RootState) => state.statistics);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchServicesDict());
  },[dispatch]);

  const reportSales1 = async () => {
    try {
      const response = await statisticsService.reportSales1({
        dateFrom: sales1_dateFrom,
        dateTo: sales1_dateTo,
      });

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sales_report.xlsx'); // Укажите имя файла и его расширение
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(openCloseDialogSalesReport(false));
    } catch(e: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while prepare report: ' + e.message,
      }));
    }
  };

  return (
    <Dialog open={openSales} onClose={() => dispatch(openCloseDialogSalesReport(false))}>
      <DialogTitle>Sales report</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"ru-RU"}>
          <DatePicker
              value={sales1_dateFrom}
              label="Period from"
              onChange={(value: DateTime | null) => {
                dispatch(setInputSalesDateFrom( value ? value.toISODate() : '' ));
              }}
              renderInput={(props) =>
                <TextField {...props}
                 sx={{
                   marginTop: 2,
                   marginRight: 2,
                   marginBottom: 2,
                 }}
                />
              }
          />
          <DatePicker
              label="Period to"
              value={sales1_dateTo}
              onChange={(value: DateTime | null) => {
                dispatch(setInputSalesDateTo( value ? value.toISODate() : '' ));
              }}
              renderInput={(props) =>
                <TextField
                    sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                  {...props}
                />
              }
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(openCloseDialogSalesReport(false))} color="primary">
          Cancel
        </Button>
        <Button onClick={reportSales1} variant="contained" color="primary">Export Excel report</Button>
      </DialogActions>
    </Dialog>
  );
};
