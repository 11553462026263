import { Button, Box, TextField, Container } from '@mui/material';
import Link from '@mui/material/Link';
import React, { FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth/auth.service';
import logo from '../../assets/images/main-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setError, setInputEmail, setInputPassword } from './loginPage.slice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const { email, password, error } = useSelector((state: RootState) => state.login);
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.checkAuth()) {
      navigate('/app');
    }
  }, [navigate]);

  const loginHandler = async (e: FormEvent) => {
    e.preventDefault();
    const result = await AuthService.login(email, password);
    if (result.success) {
      navigate('/app');
    } else {
      dispatch(setError(result.error));
    }
  };

  return (
    <Container sx={{
      backgroundColor: "#84B921",
      height: '100vh', // Занимает 100% высоты экрана
      display: 'flex',
      justifyContent: 'center', // Центрируем по горизонтали
      alignItems: 'center', // Центрируем по вертикали
    }} maxWidth={false}>
      <Box sx={{
        width: 720,
        height: 486,
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '32px'
      }}>
        <Box sx={{
          textAlign: 'center'
        }}>
          <img src={logo} alt="logo" width={234}/>
        </Box>

        <Box sx={{
          fontWeight: 'bold',
          fontSize: '24px',
          color: '#434343',
          padding: '24px 0px'
          }}
        >Login</Box>
        <Box
            component="form"
            onSubmit={loginHandler}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 1,
              '& > *': { // применить стили ко всем дочерним элементам
                width: '100%', // установить ширину 100%
              },
            }}
        >
          <Box>E-mail</Box>
          <TextField
              onChange={(e) => dispatch(setInputEmail(e.target.value))}
              InputProps={{ sx: { borderRadius: '20px', } }}
          />
          <Box>Password</Box>
          <TextField
              type="password"
              autoComplete="current-password"
              onChange={(e) => dispatch(setInputPassword(e.target.value))}
              InputProps={{ sx: { borderRadius: '20px', } }}
          />
          <Button type="submit" variant="contained" sx={{
            marginTop: 4
          }}>Login</Button>
        </Box>
          <Box sx={{
            paddingTop: 2,
            color: 'red',
            fontSize: 14,

          }}>
            {error}&nbsp;
          </Box>
        <Box
          sx={{
            paddingTop: 3,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}
        >
          <Box sx={{ width: '100%' }} />
          <Link href="/forgot-password" color="#84B921"> Forgot&nbsp;password</Link>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
