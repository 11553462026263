import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

type DialogDeleteProps = {
  question: string,
  open: boolean,
  onClose: () => void,
  onSubmit: () => void,
};

export const DialogDelete = (props: DialogDeleteProps) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <Typography>
          {props.question}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={props.onSubmit} color="primary">
          Confirm Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
