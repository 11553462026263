import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { Specialist } from '../../services/specialists/specialists.types';

export type StatisticsPageState = {
  sales: {
    open: boolean;
    fields: {
      dateFrom: string,
      dateTo: string,
      specialists: Specialist[],
      services: number[],
      studios: number[],
    }
  }
};

const initialState: StatisticsPageState = {
  sales: {
    open: false,
    fields: {
      dateFrom: DateTime.now().minus({ month: 1}).toISODate(),
      dateTo: DateTime.now().toISODate(),
      specialists: [],
      services: [],
      studios: [],
    }
  }
};

const statisticsPageSlice = createSlice<StatisticsPageState, SliceCaseReducers<StatisticsPageState>>({
  name: 'statistics',
  initialState,
  reducers: {
    openCloseDialogSalesReport: (state, action: PayloadAction<boolean>) => {
      state.sales.open = action.payload;
      Object.assign(state.sales.fields, initialState.sales.fields);
    },
    setInputSalesDateFrom: (state, action: PayloadAction<string>) => {
      state.sales.fields.dateFrom = action.payload;
    },
    setInputSalesDateTo: (state, action: PayloadAction<string>) => {
      state.sales.fields.dateTo = action.payload;
    },
    setInputSalesStudios: (state, action: PayloadAction<number[]>) => {
      state.sales.fields.studios = action.payload;
    },
    setInputSalesServices: (state, action: PayloadAction<number[]>) => {
      state.sales.fields.services = action.payload;
    }
  },
});

const statisticsReducer = statisticsPageSlice.reducer;

export const {
  openCloseDialogSalesReport,
  setInputSalesDateFrom,
  setInputSalesDateTo,
  setInputSalesStudios,
  setInputSalesServices,
} = statisticsPageSlice.actions;

export default statisticsReducer;
