import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { RootState } from '../../../store/store';
import {
  closeServiceDialogDelete, closeServiceGroupDialogDelete, fetchServiceGroups,
  openServiceGroupDialogAddEdit, openServiceGroupDialogDelete,
} from '../servicesPage.slice';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { DialogDelete } from '../../../components/DialogDelete';
import { ServiceGroup } from '../../../services/serviceGroups/serviceGroups.types';
import serviceGroupsService from '../../../services/serviceGroups/serviceGroups.service';
import { ServiceGroupDialogAddEdit } from './ServiceGroupDialogAddEdit';
import { fetchServiceGroupsDict } from '../../../store/dictionaries.slice';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const ServiceGroupsGrid = () => {
  const dispatch: ThunkDispatch<ServiceGroup[], any, AnyAction> = useDispatch();

  const {
    data: servicesGroups,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.services.serviceGroups);

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await serviceGroupsService.delete(deletingId);
        dispatch(fetchServiceGroups());
        dispatch(fetchServiceGroupsDict());
        dispatch(closeServiceGroupDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Service group successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete service group: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'name',
    headerName: 'Name',
    width: 200
  },{
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openServiceGroupDialogAddEdit({
          mode: 'edit',
          id: params.row.id,
          name: params.row.name,
          description: params.row.description,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openServiceGroupDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    dispatch(fetchServiceGroups());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ marginBottom: 1 }}
          onClick={ () => dispatch(openServiceGroupDialogAddEdit({ mode: 'add' })) }
          startIcon={<AddCircleIcon />}
        >Add service group</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this service group?"
        onClose={() => dispatch(closeServiceDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <ServiceGroupDialogAddEdit />

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        hideFooter={true}
        rows={servicesGroups}
        disableRowSelectionOnClick
      />
    </>
  );
};

export default ServiceGroupsGrid;
