import { Box, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { DialogDelete } from '../../components/DialogDelete';
import { closeUserDialogDelete, fetchUsers, openUserDialogAddEdit, openUserDialogDelete } from './usersPage.slice';
import { UserDialogAddEdit } from './components/UserDialogAddEdit';
import UsersService from '../../services/users/users.service';
import { User } from '../../services/users/users.types';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const UsersPage = () => {
  const dispatch: ThunkDispatch<User[], any, AnyAction> = useDispatch();

  const {
    data,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.users);

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await UsersService.delete(deletingId);
        dispatch(fetchUsers());
        dispatch(closeUserDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'User successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete user: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  }, {
    field: 'email',
    headerName: 'E-mai',
    sortable: false,
    flex: 1,
  }, {
    field: 'name',
    headerName: 'Name',
    width: 200
  }, {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    flex: 1,
  }, {
    field: 'role',
    headerName: 'Role',
    sortable: false,
    flex: 1,
  }, {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openUserDialogAddEdit({
          ...params.row,
          mode: 'edit',
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openUserDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ marginBottom: 1 }}
          onClick={ () => dispatch(openUserDialogAddEdit({ mode: 'add' })) }
          startIcon={<AddCircleIcon />}
        >Add user</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this user?"
        onClose={() => dispatch(closeUserDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <UserDialogAddEdit />

      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        rows={data}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default UsersPage;
