import { Box, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { RootState } from '../../store/store';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { DialogDelete } from '../../components/DialogDelete';
import {
  clearClientGroups, closeClientGroupDialogDelete,
  fetchClientGroups, openClientGroupDialogAddEdit, openClientGroupDialogDelete,
} from './clientGroupsPage.slice';
import { ClientGroupDialogAddEdit } from './components/ClientGroupDialogAddEdit';
import { InitialValues } from '../../types/types';
import { ClientGroup } from '../../services/clientGroups/clientGroups.types';
import CustomNoRowsOverlay from '../../components/grid/CustomNoRowsOverlay';
import clientGroupsService from '../../services/clientGroups/clientGroups.service';

const ClientGroupsPage = () => {
  const dispatch: ThunkDispatch<ClientGroup[], any, AnyAction> = useDispatch();

  const {
    data,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.clientGroups);
  const { studioId } = useSelector((state: RootState) => state.app);

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await clientGroupsService.delete(deletingId);
        dispatch(fetchClientGroups(studioId));
        dispatch(closeClientGroupDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Client group successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete client group: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'name',
    headerName: 'Name',
    width: 200,
    renderCell: (params) => (
        <span style={{ fontWeight: 'bold' }}>{params.row.name}</span>
    )
  },{
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openClientGroupDialogAddEdit({
          mode: 'edit',
          id: params.row.id,
          name: params.row.name,
          description: params.row.description,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openClientGroupDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    if (studioId !== InitialValues.UNKNOWN_ID) {
      dispatch(fetchClientGroups(studioId));
    } else {
      dispatch(clearClientGroups({}));
    }

  }, [dispatch, studioId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ marginBottom: 1 }}
          onClick={ () => dispatch(openClientGroupDialogAddEdit({ mode: 'add' })) }
          startIcon={<AddCircleIcon />}
        >Add client group</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this client group?"
        onClose={() => dispatch(closeClientGroupDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <ClientGroupDialogAddEdit />

      <DataGrid
        sx={{
          '&, [class^=MuiDataGrid-root]': { border: 'none' },
          height: 'calc(100vh - 375px)',
      }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        hideFooter={true}
        columns={columns}
        slots={{ noRowsOverlay: () => <CustomNoRowsOverlay text={"No client groups"} /> }}
        rows={data}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default ClientGroupsPage;
