import { styled } from '@mui/material/styles';
import { Appointments, DragDropProvider } from '@devexpress/dx-react-scheduler-material-ui';
import React from 'react';
import { AppointmentComponentContent } from './AppointmentComponentContent';
import { classes } from './.classes';

const StyledSourceAppointmentComponent = styled(Appointments.Appointment)(() => ({
  [`& .${classes.container}`]: {
    opacity: 0.5,
  },
}));

export const SourceAppointmentComponent = ({ data, ...restProps }: DragDropProvider.SourceAppointmentProps & { studioId: number, isMatrix: boolean }) =>  {
  return (
      <StyledSourceAppointmentComponent {...restProps } resources={[]} data={data} draggable={false}>
        <AppointmentComponentContent data={data}  {...restProps} />
      </StyledSourceAppointmentComponent>
  );
};
