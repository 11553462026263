import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import {
  closeStudioDialogAddEdit,
  fetchStudios, setInputAddress,
  setInputDescription,
  setInputName, setInputRules,
  setInputTimezone, setInputWorkTimeEnd, setInputWorkTimeStart,
  StudiosPageState
} from '../studiosPage.slice';
import StudiosService from '../../../services/studios/studios.service';
import { DateTime } from 'luxon';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

export const StudioDialogAddEdit = () => {
  const dispatch: ThunkDispatch<StudiosPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { description, name, id, timezone, address, rules, workTimeStart, workTimeEnd },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.studios.dialogs.addEdit);

  const timezones = useSelector((state: RootState) => state.dictionaries.timezones);

  const dialogClose = () => dispatch(closeStudioDialogAddEdit({}));

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;
    const body = { name, description, rules, address, workTimeStart, workTimeEnd } as {
      name: string;
      description: string;
      address: string;
      rules: string;
      timezone: string;
      workTimeStart: string;
      workTimeEnd: string;
    };

    try {
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await StudiosService.add(body);
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await StudiosService.edit(id as number, body);
      }

      dispatch(fetchStudios());
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} studio: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Add studio' : 'Edit studio'}</DialogTitle>
      <DialogContent>
        <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => dispatch(setInputName(e.target.value))}
            sx={{ marginTop: '20px' }}
        />
        <TextField
            multiline={true}
            rows={6}
            label="Description"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputDescription(e.target.value))}
            value={description}
            sx={{ marginTop: '20px' }}
        />
        <TextField
            label="Address"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputAddress(e.target.value))}
            value={address}
            sx={{ marginTop: '20px' }}
        />
        <TextField
            multiline={true}
            rows={3}
            label="Rules"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputRules(e.target.value))}
            value={rules}
            sx={{ marginTop: '20px' }}
        />
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"ru-RU"}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TimePicker
              label="Work start"
              value={DateTime.fromISO(workTimeStart).toJSDate()}
              onChange={(dateTime: DateTime | null, keyboardInputValue?: string) => {
                if (dateTime) {
                  dispatch(setInputWorkTimeStart(dateTime.toFormat('HH:mm')));
                } else {
                  dispatch(setInputWorkTimeStart(''));
                }
              }}
              renderInput={(props) => <TextField {...props} sx={{
                marginTop: '20px',
                marginRight: '10px',
                width: '50%'
              }} />}
            />
            <TimePicker
              label="Work end"
              value={DateTime.fromISO(workTimeEnd).toJSDate()}
              onChange={(dateTime: DateTime | null, keyboardInputValue?: string) => {
                if (dateTime) {
                  dispatch(setInputWorkTimeEnd(dateTime.toFormat('HH:mm')));
                } else {
                  dispatch(setInputWorkTimeEnd(''));
                }
              }}
              renderInput={(props) => <TextField {...props} sx={{
                marginTop: '20px',
                width: '50%'
              }} />}
            />
          </Box>
        </LocalizationProvider>

        <Autocomplete
            options={timezones.map((timezone) => ({
              id: timezone,
              label: timezone,
            }))}
            fullWidth
            disabled={true}
            inputValue={timezone}
            onChange={(_, newInputValue) => dispatch(setInputTimezone(newInputValue?.label || ''))}
            sx={{ marginBottom: 1, marginTop: 1 }}
            renderInput={(params) => <TextField
                {...params}
                label="Timezone"
                variant="outlined"
                sx={{
                  marginTop: '10px'
                }}
            />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Add studio'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

