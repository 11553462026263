import { IsoDateString } from '../types/types';
import { DateTime } from 'luxon';

export const timeToIsoDateString = <T extends string | undefined>(time: T): T extends string ? IsoDateString : undefined => {
  if (typeof time === 'string') {
    const [startHours, startMinutes] = time.split(':');

    if (!startMinutes) {
      throw new Error('Bad time: ' + time);
    }

    return DateTime.now().set({
      hour: Number(startHours),
      minute: Number(startMinutes),
    }).toISO() as T extends string ? IsoDateString : undefined;
  }

  return undefined as T extends string ? IsoDateString : undefined;
};
