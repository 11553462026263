const currencySign = (currency: string): string => {
  switch(currency) {
    case 'GEL':
      return '₾';
    default:
      return currency;
  }
}

export default currencySign;
