import { MatrixEdit, MatrixView, MatrixViewRaw } from '../../services/matrix/matrix.types';
import { dateToTimestring } from '../../utils/dateToTimestring';
import { timeIsoStringToTime } from '../../utils/timeIsoStringToTime';
import _ from 'lodash';
import { dateToIsoString } from '../../utils/dateToIsoString';

export const matrixViewRawToMatrixView = (matrixView: MatrixViewRaw): MatrixView => {
  return {
    ...matrixView,
    startDate: dateToIsoString(matrixView.startDate),
    endDate: dateToTimestring(matrixView.startDate),
  }
};

export const matrixViewToMatrixEdit = (matrixView: MatrixView): MatrixEdit => {
  return {
    ..._.omit(matrixView, 'id'),
    start: timeIsoStringToTime(matrixView.startDate),
    end: timeIsoStringToTime(matrixView.endDate),
  }
};
