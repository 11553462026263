import React from 'react';
import './App.css';
import LoginPage from './pages/login/LoginPage';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ApplicationPage from './pages/ApplicationPage';
import LogoutPage from './pages/logout/LogoutPage';
import { Provider } from 'react-redux';
import store from './store/store';
import { DialogInfo } from './components/dialog/DialogInfo';
import { createTheme, ThemeProvider } from '@mui/material';
import ForgotPasswordPage from './pages/forgotPassword/ForgotPassword';
import Page404 from './pages/404/Page404';

const theme = createTheme({
  palette: {
    primary: {
      main: '#84B921',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LoginPage/>} />
              <Route path="/app" element={<ApplicationPage/>} />
              <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
              <Route path="/logout" element={<LogoutPage/>} />
              <Route path="*" element={<Page404/>} />
            </Routes>
          </BrowserRouter>
        </div>
        <DialogInfo />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
