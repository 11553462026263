import { ServiceCreateDTO, ServiceDTO } from './services.types';
import BaseService from '../base/base.service';

class ServicesService extends BaseService<ServiceDTO, ServiceCreateDTO> {
  readonly url = 'services';
}

const servicesService = new ServicesService();

export default servicesService;
