import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import {
  closePricelistDialogAddEdit, fetchPricelist,
  PricelistPageState, setInputDurationUnit, setInputDurationValue,
  setInputLimit,
  setInputPrice, setInputServiceGroupId,
} from '../pricelistPage.slice';
import PricelistService from '../../../services/pricelist/pricelist.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import React, { useEffect } from 'react';
import { fetchServiceGroupsDict, fetchStudiosDict } from '../../../store/dictionaries.slice';
import { getServiceGroups, getServiceGroupsWithDeleted } from '../../../store/dictionaries.selectors';
import currencySign from '../../../utils/currencySign';
import { PricelistEdit } from '../../../services/pricelist/pricelist.types';

type Units = 'day' | 'month' | 'unlimited';

const durationUnits: {
  id: Units,
  label: string,
}[] = [{ id: 'day', label: 'Days'}, { id: 'month', label: 'Months' }, { id: 'unlimited', label: 'Unlimited' }];

const PricelistDialogAddEdit = () => {
  const dispatch: ThunkDispatch<PricelistPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { serviceGroupId, price, limit, id, durationValue, durationUnit },
    mode,
    isOpen,
  } = useSelector((state: RootState) => state.pricelist.dialogs.addEdit);
  const { currency } = useSelector((state: RootState) => state.pricelist);
  const { studios } = useSelector((state: RootState) => state.dictionaries);
  const {} = useSelector((state: RootState) => state.app.studioId)
  const { studioId }: { studioId: number } = useSelector((state: RootState) => state.app);
  const serviceGroups = useSelector(getServiceGroups);
  const serviceGroupsWithDeleted = useSelector(getServiceGroupsWithDeleted);

  const dialogClose = () => dispatch(closePricelistDialogAddEdit({}));

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;

    // todo: validation

    try {
      const data: PricelistEdit = {
        price: Number(price),
        limit: Number(limit),
        rules: {
          duration: {
            unit: durationUnit,
            value: durationValue ? Number(durationValue) : null,
          }
        },
      };

      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await PricelistService.add({
          studioId,
          serviceGroupId: Number(serviceGroupId),
          ...data,
        });
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];
        await PricelistService.edit(id as number, data);
      }

      dispatch(fetchPricelist(studioId));
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} pricelist: ${error.message}`,
      }));
    }
  };

  useEffect(() => {
    if (serviceGroups.length === 0) {
      dispatch(fetchServiceGroupsDict());
    }
    if (studios.length === 0) {
      dispatch(fetchStudiosDict());
    }
  }, [dispatch, serviceGroups, studios]);

  return (
    <Dialog open={isOpen} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Add pricelist' : 'Edit pricelist'}</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={serviceGroups.map((group) => ({
            id: group.id,
            label: group.name,
          }))}
          fullWidth
          disabled={mode !== 'add'}
          inputValue={serviceGroupsWithDeleted.find((group) => group.id === serviceGroupId)?.name || ''}
          onChange={(_, value: { id: number } | null) => dispatch(setInputServiceGroupId(value? value.id : -1))}
          sx={{ marginBottom: 1, marginTop: 1 }}
          renderInput={(params) => <TextField
            {...params}
            label="Service group"
            variant="outlined"
          />}
        />
        <TextField
          label="Limit"
          variant="outlined"
          fullWidth
          value={limit}
          onChange={(e) => dispatch(setInputLimit(e.target.value))}
          sx={{ marginBottom: 1, marginTop: 1 }}
          type={'number'}
          inputProps={{ min: 1 }}
        />
        <TextField
          label="Price"
          variant="outlined"
          fullWidth
          value={price}
          onChange={(e) => dispatch(setInputPrice(e.target.value))}
          sx={{ marginTop: 1 }}
          type={'number'}
          InputProps={{ endAdornment: <InputAdornment position="end">{currencySign(currency)}</InputAdornment> }}
          inputProps={{ min: 0.1 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginTop: 1 }}>
          <Autocomplete
            options={durationUnits}
            value={durationUnits.find((unit) => unit.id === durationUnit)}
            fullWidth
            disableClearable
            onChange={(_, value: { id: Units }) => dispatch(setInputDurationUnit(value ? value.id : null))}
            sx={{ marginBottom: 1, marginTop: 1 }}
            renderInput={(params) => <TextField
                {...params}
                label="Period"
                variant="outlined"
            />}
          />
          <TextField
            label="Limit"
            variant="outlined"
            fullWidth
            value={durationValue || ''}
            disabled={durationUnit === 'unlimited'}
            onChange={(e) => dispatch(setInputDurationValue(e.target.value ? Number(e.target.value) : null))}
            sx={{ marginLeft: 2 }}
            type={'number'}
            inputProps={{ min: 1 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Add pricelist'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PricelistDialogAddEdit;
