import { Box, Button, TextField } from '@mui/material';
import { setStep } from '../forgotPassword.slice';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthService from '../../../services/auth/auth.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';

const ForgotPasswordRequest = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');

  const onForgotPasswordRequestHandler = async () => {
    try {
      await AuthService.sendRecoverCode(email);
      dispatch(setStep(2));
    } catch (e: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error: ' + e.message,
      }));
    }
  };

  return (
      <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 1,
            '& > *': { // применить стили ко всем дочерним элементам
              width: '100%', // установить ширину 100%
            },
          }}
      >
        <Box>Please provide your email address, and you will receive a recovery code in your mailbox.</Box>
        <TextField
            required={true}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{ sx: { borderRadius: '20px', } }}
        />
        <Button variant="contained" onClick={onForgotPasswordRequestHandler} sx={{
          marginTop: 4
        }}>Send code</Button>
      </Box>
  );
}

export default ForgotPasswordRequest;
