import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import AuthService from '../../../services/auth/auth.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const onForgotPasswordConfirmHandler = async (e: any) => {
    e.preventDefault();

    if (!e.target.checkValidity()) { return; }

    if (password !== repeatPassword) {
      setRepeatPasswordError('Password and repeat password should be equal.');
      return;
    } else {
      setRepeatPasswordError('');
    }

    try {
      await AuthService.confirmForgotPassword(email, code, password);

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: 'Password successfully changed.'
      }));
      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
        navigate('/');
      }, 2000);
    } catch (e: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error: ' + e.message,
      }));
    }
  };

  const inputProps = { sx: { borderRadius: '20px', } };

  return (
    <Box
      component='form'
      onSubmit={onForgotPasswordConfirmHandler}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: 1,
        '& > *': { // применить стили ко всем дочерним элементам
          width: '100%', // установить ширину 100%
        },
      }}
    >
      <Box>E-mail</Box>
      <TextField
        required={true}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={inputProps}
      />
      <Box>Recover code</Box>
      <TextField
        required={true}
        onChange={(e) => setCode(e.target.value)}
        InputProps={inputProps}
      />
      <Box>New password</Box>
      <TextField
        required={true}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        InputProps={inputProps}
      />
      <Box>Confirm new password</Box>
      <TextField
        required={true}
        type="password"
        error={!!repeatPasswordError}
        helperText={repeatPasswordError}
        onChange={(e) => setRepeatPassword(e.target.value)}
        InputProps={inputProps}
      />
      <Button type="submit" variant="contained" sx={{
        marginTop: 4
      }}>Change password</Button>
    </Box>
  );
}

export default ForgotPasswordConfirm;
