import * as React from 'react';
import { useEffect } from 'react';
import {
  AppointmentModel,
  ChangeSet,
  EditingState,
  GroupingState,
  IntegratedEditing,
  IntegratedGrouping,
  ViewState
} from '@devexpress/dx-react-scheduler';
import {
  AppointmentForm,
  Appointments,
  DayView,
  DragDropProvider,
  GroupingPanel,
  Resources,
  Scheduler,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import {
  addMatrixRecord,
  clearMatrix,
  fetchMatrix, setAppointmentChanges,
  setDisableSaveButton,
  setEditingAppointment, setMatrixStudioId,
  setRoomId,
  setShowAddEditMatrixForm,
} from './matrixPage.slice';
import {
  AppointmentComponent,
  LabelComponent,
  TextEditor,
  TimeField
} from '../../components/appointments/components';
import { SourceAppointmentComponent } from '../../components/appointments/SourceAppointmentComponent';
import { DraftAppointmentComponent } from '../../components/appointments/DraftAppointmentComponent';
import { Matrix, MatrixCreate, MatrixEdit, MatrixView, MatrixViewRaw } from '../../services/matrix/matrix.types';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { RoomDict } from '../../services/rooms/rooms.types';
import MatrixService from '../../services/matrix/matrix.service';
import { timeGreaterThenTime } from '../../utils/timeGreaterThenTime';
import { scheduleRecordsAreCross } from '../../utils/scheduleRecordsAreCross';
import { matrixViewRawToMatrixView } from './matrixPage.utils';
import { DateTime } from 'luxon';
import { fetchRoomsDict, prepareResources } from '../../store/dictionaries.slice';
import { AppointmentCommandLayoutComponent } from './components/AppointmentCommandLayout';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { Studio } from '../../services/studios/studios.types';
import { setWorkingTime } from '../applicationPage.slice';
import { InitialValues } from '../../types/types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import _ from 'lodash';

const isoParams = { includeOffset: false, suppressMilliseconds: false };

const MatrixPage = () => {
  const dispatch: ThunkDispatch<Matrix[], { }, AnyAction> = useDispatch();

  const {
    data: appointments,
    resources,
    roomId,
    rooms,
    showAddEditMatrixForm,
    appointmentData,
    editingAppointment,
    appointmentChanges,
    disableSaveButton,
  } = useSelector((state: RootState) => state.matrix);
  const { studioId, startHours, endHours, currentPage } = useSelector((state: RootState) => state.app);
  const { studioId: matrixStudioId } = useSelector((state: RootState) => state.matrix);
  const { studios } = useSelector((state: RootState) => state.dictionaries);

  useEffect(() => {
    dispatch(setShowAddEditMatrixForm(false));
  }, [dispatch, currentPage]);

  useEffect(() => {
    const isValid = !!(editingAppointment?.limit && editingAppointment?.serviceId && editingAppointment.specialistId);
    dispatch(setDisableSaveButton(!isValid));
  }, [dispatch, editingAppointment]);

  useEffect(() => {
    if (rooms[0]) {
      dispatch(setRoomId(rooms[0].id));
    } else {
      dispatch(setRoomId(InitialValues.UNKNOWN_ID));
    }
  }, [dispatch, rooms]);

  useEffect(() => {
    dispatch(clearMatrix({ }));
    if (roomId !== InitialValues.UNKNOWN_ID) {
      (async () => {
        await dispatch(fetchMatrix({ roomId }));
        dispatch((setMatrixStudioId(studioId)));
      })();
    }
  }, [dispatch, roomId]);

  useEffect(() => {
    if (!showAddEditMatrixForm) {
      dispatch(setAppointmentChanges({}));
    }
  }, [dispatch, showAddEditMatrixForm]);

  useEffect(() => {
    dispatch(setShowAddEditMatrixForm(false));
    dispatch(setRoomId(InitialValues.UNKNOWN_ID));
    dispatch(clearMatrix({}));
    if (studioId !== InitialValues.UNKNOWN_ID) {
      (async () => {
        await dispatch(prepareResources(studioId));
        await dispatch(fetchRoomsDict(studioId));
        const studio = studios.find((s) => s.id === studioId) as Studio;
        dispatch(setWorkingTime({ start: studio.workTimeStart, end: studio.workTimeEnd }))
      })();
    }
  }, [dispatch, studioId]);

  const allowChange = (record: MatrixView | MatrixViewRaw): boolean => {
    if (record.startDate instanceof Date) {
      record = matrixViewRawToMatrixView(record as MatrixViewRaw);
    }
    return !appointments.some((r) => scheduleRecordsAreCross(record as MatrixView, r));
  };

  const onAppointmentChange = async (data: ChangeSet) => {
    if (data?.changed?.['undefined']) {
      data.added = data.changed['undefined'];
      delete data.changed;
    }

    dispatch(setDisableSaveButton(true)); // TODO: не работает полноценно
    // так как при изменении меняется edititingRecord

    try {
      if (data.deleted && typeof data.deleted === 'number') {
        await MatrixService.delete(data.deleted);

      } else if (data.added) {
        const added = editingAppointment as MatrixView;

        const addedData = {
          roomId,
          specialistId: added.specialistId,
          serviceId: added.serviceId,
          limit: added.limit,
          day: added.day,
          start: DateTime.fromISO(added.startDate).toFormat('HH:mm'),
          end: DateTime.fromISO(added.endDate).toFormat('HH:mm'),
        };

        const id = await MatrixService.add(addedData as MatrixCreate);
        dispatch(addMatrixRecord({  ...addedData, id } ));

        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Successfully added matrix record',
        }));
      } else if (data.changed) {
        const [[changeId, changed]] = Object.entries(data.changed);
        const changingRecord = appointments.find((record) => record.id === Number(changeId));

        if (!changingRecord) {
          throw new Error('Record not found');
        }
        const dirtyRecordForChecks = { ...changingRecord };

        const params: MatrixEdit = _.omitBy({
          serviceId: changed.serviceId,
          specialistId: changed.specialistId,
          day: changed.day,
          start: changed.startDate ? DateTime.fromISO(changed.startDate).toFormat('HH:mm') : undefined,
          end: changed.endDate ? DateTime.fromISO(changed.endDate).toFormat('HH:mm') : undefined,
          limit: changed.limit,
        }, _.isUndefined);

        Object.assign(dirtyRecordForChecks, changed);

        if (!allowChange(dirtyRecordForChecks)) {
          throw new Error("Invalid time");
        }

        if (timeGreaterThenTime(dirtyRecordForChecks.startDate, dirtyRecordForChecks.endDate)) {
          throw new Error("Invalid start time");
        }

        dispatch(setEditingAppointment(dirtyRecordForChecks)); // change data before window will hide

        try {
          await MatrixService.edit(Number(changeId), params);
          dispatch(fetchMatrix({ roomId }));
        } catch(e: any) {
          dispatch(toggleDialogInfo({
            open: true,
            type: 'error',
            message: 'Cannot edit matrix record: ' + e.message,
          }));
        }
      }

      dispatch(setShowAddEditMatrixForm(false));
      return true;
    } catch(e: any) {
      dispatch(setDisableSaveButton(false));

      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while processing matrix: ' + e.message,
      }));
      return false;
    }
  }

  const addMatrixButtonClick = () => {
    dispatch(setEditingAppointment({
      startDate: DateTime.now().set({ hour: 12, minute: 0 }).toISO(isoParams),
      endDate: DateTime.now().set({ hour: 13, minute: 0 }).toISO(isoParams),
      day: 1,
      specialistId: '',
      serviceId: '',
      limit: '',
    }));
    dispatch(setShowAddEditMatrixForm(true));
  };

  const addMatrixFieldClick = (addedAppointment: any) => {
    dispatch(setShowAddEditMatrixForm(true));
    dispatch(setEditingAppointment({
      startDate: DateTime.fromJSDate(addedAppointment.startDate).toISO(isoParams),
      endDate: DateTime.fromJSDate(addedAppointment.startDate).plus({ hours: 1 }).toISO(isoParams),
      day: addedAppointment.day || undefined,
      specialistId: '',
      serviceId: '',
      limit: '',
    }));
  };

  return (
    <Box>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
        marginTop: '10px'
      }}>
        <FormControl sx={{ marginLeft: '10px' }}>
          <InputLabel id="matrix-select-room-label">Room</InputLabel>
          <Select
              labelId="matrix-select-room-label"
              id="matrix-select-room"
              sx={{
                minWidth: '200px',
                borderRadius: '15px',
              }}
              label="Room"
              value={roomId !== InitialValues.UNKNOWN_ID ? roomId : ''}
              onChange={(e) => dispatch(setRoomId(e.target.value))}
          >
            {(rooms || []).map((room: RoomDict) => (
                <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{
          width: '100%'
        }} />

        <Box sx={{
          marginRight: '10px'
        }}>
          <Button
            variant="contained"
            color="primary"
            onClick={addMatrixButtonClick}
            startIcon={<AddCircleIcon />}
          >Add&nbsp;record</Button>
        </Box>
      </Box>

      <Scheduler
        data={appointments}
        height={"auto"}
        locale={"ru-RU"}
        firstDayOfWeek={1}
      >
        <ViewState />
        <DayView
          startDayHour={startHours}
          endDayHour={endHours}
          dayScaleCellComponent={() => (<></>)}
        />
        <Appointments
          appointmentComponent={(data: any) => AppointmentComponent({ ...data, studioId: matrixStudioId }, () => true)}
        />
        <EditingState
          onCommitChanges={onAppointmentChange}
          editingAppointment={editingAppointment}
          appointmentChanges={appointmentChanges}
          onAddedAppointmentChange={addMatrixFieldClick}
          // onEditingAppointmentChange={(editingAppointment) => {
          //   if (editingAppointment && !editingAppointment.type) { // for some reason if case is DD - this event is calling
          //     dispatch(setEditingAppointment(editingAppointment));
          //     dispatch(setShowAddEditMatrixForm(true));
          //   }
          // }}
          onEditingAppointmentChange={(editingAppointment) => {
            if (editingAppointment) {
              dispatch(setEditingAppointment(editingAppointment));

              if (!editingAppointment.type && allowChange(editingAppointment as any)) { // for some reason if case is DD - this event is calling
                dispatch(setShowAddEditMatrixForm(true));
              }
            }
          }}
          onAppointmentChangesChange={(changes) => {
            if (changes.startDate && changes.startDate instanceof Date) {
              changes.startDate = DateTime.fromJSDate(changes.startDate).toISO(isoParams);
            }
            if (changes.endDate && changes.endDate instanceof Date) {
              changes.endDate = DateTime.fromJSDate(changes.endDate).toISO(isoParams);
            }
            if (Object.keys(changes).length > 0) {
              dispatch(setAppointmentChanges(changes));
            }
          }}

        />
        <IntegratedEditing />
        <AppointmentForm
          visible={showAddEditMatrixForm}
          appointmentData={appointmentData as AppointmentModel}
          textEditorComponent={TextEditor}
          labelComponent={LabelComponent}
          booleanEditorComponent={() => null}
          dateEditorComponent={TimeField}
          commandLayoutComponent={(data: any) => <AppointmentCommandLayoutComponent { ...data} disableSaveButton={disableSaveButton}/>}
        />
        <Resources
          data={resources}
          mainResourceName="day"
        />
        <GroupingState
          grouping={[{ resourceName: 'day' }]}
        />
        <DragDropProvider
          sourceAppointmentComponent={(data: any) => SourceAppointmentComponent({ ...data, isMatrix: true })}
          draftAppointmentComponent={(data: any) => DraftAppointmentComponent({ ...data, isMatrix: true })}
        />
        <IntegratedGrouping />
        <GroupingPanel />
      </Scheduler>
    </Box>
  );
}

export default React.memo(MatrixPage);
