import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type SupportPageState = {
  subject: string,
  message: string,
  subjectInitial: boolean;
  messageInitial: boolean;
  dialogs: {
    success: boolean;
  }
};

const initialState: SupportPageState = {
  subject: '',
  message: '',
  subjectInitial: true,
  messageInitial: true,
  dialogs: {
    success: false,
  }
};

const supportPageSlice = createSlice<SupportPageState, SliceCaseReducers<SupportPageState>>({
  name: 'my-profile',
  initialState,
  reducers: {
    setInputSubject: (state, action: PayloadAction<string>) => {
      state.subject = action.payload;
      if (state.subject.length > 0 && state.subjectInitial) {
        state.subjectInitial = false;
      }
    },
    setInputMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      if (state.message.length > 0 && state.messageInitial) {
        state.messageInitial = false;
      }
    },
    setValidateMode: (state, action: PayloadAction<boolean>) => {
      state.messageInitial = !action.payload;
      state.subjectInitial = !action.payload;
    }
  },
});

const supportReducer = supportPageSlice.reducer;

export const {
  setInputSubject,
  setInputMessage,
  setValidateMode,
} = supportPageSlice.actions;

export default supportReducer;
