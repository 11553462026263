import { Box } from '@mui/material';

const SettingsPage = () => {
  return (
    <Box>
      Settings
    </Box>
  );
}

export default SettingsPage;
