import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Room } from '../../services/rooms/rooms.types';
import RoomsService from '../../services/rooms/rooms.service';

type DialogAddEditAction = {
  mode: 'add',
} | {
  mode: 'edit',
  id: number,
  name: string,
  description: string,
  studioId: number;
};

export type RoomsPageState = {
  data: Room[],
  dialogs: {
    addEdit: {
      open: boolean;
      mode: 'add' | 'edit',
      success: boolean;
      inputs: {
        id: number | null;
        name: string;
        studioName: string;
        description: string;
      }
    },
    delete: {
      open: boolean;
      deletingId: number | null;
    },
  },
};

const initialState: RoomsPageState = {
  data: [],
  dialogs: {
    addEdit: {
      open: false,
      mode: 'add',
      success: false,
      inputs: {
        id: null,
        studioName: '',
        name: '',
        description: '',
      }
    },
    delete: {
      open: false,
      deletingId: null,
    },
  },
};

export const fetchRooms = createAsyncThunk<Room[], number>(
'services/fetchRooms',
async (studioId, { rejectWithValue }) => {
  try {
    const response = await RoomsService.getList(studioId);
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch services');
  }
});

const roomsPageSlice = createSlice<RoomsPageState, SliceCaseReducers<RoomsPageState>>({
  name: 'services',
  initialState,
  reducers: {
    clearRooms: (state) => {
      state.data = [];
    },
    closeRoomDialogAddEdit: (state) => {
      state.dialogs.addEdit.open = false;
    },
    openRoomDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.dialogs.addEdit.open = true;
      state.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.dialogs.addEdit.inputs, initialState.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.dialogs.addEdit.inputs, action.payload);
      }
    },
    closeRoomDialogDelete: (state) => {
      state.dialogs.delete.open = false;
    },
    openRoomDialogDelete: (state, action: PayloadAction<number>) => {
      state.dialogs.delete.deletingId = action.payload;
      state.dialogs.delete.open = true;
    },
    setInputId: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.id = action.payload;
    },
    setInputStudioName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.studioName = action.payload;
    },
    setInputName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.name = action.payload;
    },
    setInputDescription: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.description = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRooms.fulfilled, (state: RoomsPageState, action) => {
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          state.data = action.payload;
        }
      });
  },
});

const roomsReducer = roomsPageSlice.reducer;

export const {
  clearRooms,
  setInputId,
  setInputStudioName,
  setInputName,
  setInputDescription,
  openRoomDialogAddEdit,
  closeRoomDialogAddEdit,
  closeRoomDialogDelete,
  openRoomDialogDelete,
} = roomsPageSlice.actions;

export default roomsReducer;
