import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React from 'react';

const GridBoolean = (params: GridCellParams) => (
    !!params.field ? <CheckIcon /> : <DoDisturbIcon />
);

export default GridBoolean;
