import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { toggleDialogInfo } from './dialogInfo.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

export const DialogInfo = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { open, message, type } = useSelector((state: RootState) => state.dialogInfo);

  return (
      <Dialog open={open}>
        <DialogTitle>{ type === 'info' ? 'Information': 'Error'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={ () => dispatch(toggleDialogInfo({ open: false }))}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography>
            {message}
          </Typography>
        </DialogContent>
      </Dialog>
  );
}
