import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import {
  closeServiceGroupDialogAddEdit,
  fetchServiceGroups,
  ServicesPageState,
  setServiceGroupInputDescription,
  setServiceGroupInputName,
} from '../servicesPage.slice';
import serviceGroupsService from '../../../services/serviceGroups/serviceGroups.service';
import { fetchServiceGroupsDict } from '../../../store/dictionaries.slice';

export const ServiceGroupDialogAddEdit = () => {
  const dispatch: ThunkDispatch<ServicesPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { id, name, description },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.services.serviceGroups.dialogs.addEdit);

  const dialogClose = () => dispatch(closeServiceGroupDialogAddEdit({}));

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;
    const body = { name, description };

    try {
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await serviceGroupsService.add(body);
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await serviceGroupsService.edit(id, body);
      }

      dispatch(fetchServiceGroups());
      dispatch(fetchServiceGroupsDict());
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} service: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Add service group' : 'Edit service group'}</DialogTitle>
      <DialogContent>
        <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => dispatch(setServiceGroupInputName(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
        />
        <TextField
            label="Description"
            multiline={true}
            rows={6}
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => dispatch(setServiceGroupInputDescription(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Add service group'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

