import { ApiService } from '../api/api.service';

class CurrenciesService {
  public static async getList(): Promise<string[]> {
    const response = await ApiService.get<string[]>(`helpers/currencies`);

    return response.data
  }
}

export default CurrenciesService;
