import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type LoginPageState = {
  email: string,
  password: string,
  error: string | null,
};

const initialState: LoginPageState = {
  email: '',
  password: '',
  error: null,
};

const loginPageSlice = createSlice<LoginPageState, SliceCaseReducers<LoginPageState>>({
  name: 'login',
  initialState,
  reducers: {
    setInputEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setInputPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

const loginReducer = loginPageSlice.reducer;

export const {
  setInputEmail,
  setInputPassword,
  setError,
} = loginPageSlice.actions;

export default loginReducer;
