import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import {
  closeSpecialistDialogAddEdit, fetchSpecialists,
  setInputDescription,
  setInputEmail,
  setInputName,
  SpecialistsPageState,
} from '../specialistsPage.slice';
import specialistsService from '../../../services/specialists/specialists.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';

export const SpecialistDialogAddEdit = () => {
  const dispatch: ThunkDispatch<SpecialistsPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { email, description, name, id },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.specialists.dialogs.addEdit);

  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => dispatch(closeSpecialistDialogAddEdit({}));

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;

    try {
      if (!studioId) {
        throw new Error('Studio not found');
      }

      if (mode === 'add') {
        [textAction, textActionPast] = ['invite', 'invited'];

        if (email === '') {
          throw new Error('E-mail cannot be empty.');
        }

        await specialistsService.add({
          email,
          studioId,
          name,
          description,
        });
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await specialistsService.edit(id as number, studioId, {
          name,
          description,
        });
      }

      dispatch(fetchSpecialists(studioId));
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} specialist: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Invite specialist' : 'Edit specialist'}</DialogTitle>
      <DialogContent>
        {mode === 'add' && (
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => dispatch(setInputEmail(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
          />
        )}

        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => dispatch(setInputName(e.target.value))}
          sx={{ marginBottom: 1, marginTop: 1 }}
        />
        <TextField
          multiline={true}
          rows={6}
          label="Description"
          variant="outlined"
          fullWidth
          onChange={(e) => dispatch(setInputDescription(e.target.value))}
          value={description}
          sx={{ marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Invite specialist'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
