import {
  CheckClientResponse,
  Client, ClientCreate,
  ClientCreateDTO,
  ClientExtended,
  ClientExtendedDTO,
  GetListRequest
} from './clients.types';
import { ApiService } from '../api/api.service';
import { IGetListResponse } from '../base/base.types';
import BaseService from '../base/base.service';

class ClientsService extends BaseService<Client> {
  readonly url = 'clients';
  // @ts-ignore
  public override async getList(params: GetListRequest): Promise<IGetListResponse<Client>> {
    const { studioId, pageSize, page, filter } = params;

    let query = `?studioId=${studioId}`;
    if (pageSize) {
      query += `&limit=${pageSize}`;
    }
    if (page && pageSize) {
      query += `&offset=${(page-1)*pageSize}`;
    }

    if (filter && filter !== '') {
      query += `&filters=${filter}`;
    }

    const response = await ApiService.get<IGetListResponse<Client>>(encodeURI(this.url + query)); // try / catch
    return response.data;
  }

  public async getOne(id: number): Promise<ClientExtended> {
    const response = await ApiService.get<ClientExtendedDTO>(`${this.url}/${id}`); // try / catch

    return response.data;
  }

  public async check(email: string, studioId: number): Promise<CheckClientResponse> {
    const response = await ApiService.get<{ status: CheckClientResponse }>(`${this.url}/check-email?email=${email}&studioId=${studioId}`); // try / catch
    return response.data.status;
  }

  public async addToStudio(email: string, studioId: number): Promise<void> {
    await ApiService.post(`${this.url}/add-client-studio`, {
      email, studioId,
    });
  }

  public async create(args: ClientCreate): Promise<void> {
    await ApiService.post<ClientCreateDTO>(`${this.url}`, args);
  }

  public async setGroups(id: number, groupIds: number[]): Promise<void> {
    await ApiService.patch(`${this.url}/${id}`, {
      groups: groupIds,
    });
  }
}

const clientsService = new ClientsService();

export default clientsService;
