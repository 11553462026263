import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import specialistsService from '../../services/specialists/specialists.service';
import { Specialist } from '../../services/specialists/specialists.types';

type DialogAddEditAction = {
  mode: 'add',
} | {
  mode: 'edit',
  id: number,
  name: string,
  description: string,
};

export type SpecialistsPageState = {
  data: Specialist[],
  dialogs: {
    addEdit: {
      open: boolean;
      mode: 'add' | 'edit',
      success: boolean;
      inputs: {
        id: number | null;
        studioName: string;
        email: string;
        name: string;
        description: string;
      }
    },
    delete: {
      open: boolean;
      deletingId: number | null;
    },
  },
};

const initialState: SpecialistsPageState = {
  data: [],
  dialogs: {
    addEdit: {
      open: false,
      mode: 'add',
      success: false,
      inputs: {
        id: null,
        studioName: '',
        email: '',
        name: '',
        description: '',
      }
    },
    delete: {
      open: false,
      deletingId: null,
    },
  },
};

export const fetchSpecialists = createAsyncThunk<Specialist[], number>(
'specialists/fetchSpecialists',
async (studioId: number, { rejectWithValue}) => {
  try {
    const response = await specialistsService.getList(studioId);
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch specialists');
  }
});

const specialistsPageSlice = createSlice<SpecialistsPageState, SliceCaseReducers<SpecialistsPageState>>({
  name: 'specialists',
  initialState,
  reducers: {
    closeSpecialistDialogAddEdit: (state) => {
      state.dialogs.addEdit.open = false;
    },
    openSpecialistDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.dialogs.addEdit.open = true;
      state.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        state.dialogs.addEdit.inputs.name = '';
        state.dialogs.addEdit.inputs.description = '';
        state.dialogs.addEdit.inputs.email = '';
      } else {
        state.dialogs.addEdit.inputs.id = action.payload.id;
        state.dialogs.addEdit.inputs.name = action.payload.name;
        state.dialogs.addEdit.inputs.description = action.payload.description;
      }
    },
    closeSpecialistDialogDelete: (state) => {
      state.dialogs.delete.open = false;
    },
    openSpecialistDialogDelete: (state, action: PayloadAction<number>) => {
      state.dialogs.delete.deletingId = action.payload;
      state.dialogs.delete.open = true;
    },
    setInputHiddenId: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.id = action.payload;
    },
    setInputEmail: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.email = action.payload;
    },
    setInputName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.name = action.payload;
    },
    setInputStudioName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.studioName = action.payload;
    },
    setInputDescription: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.description = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialists.fulfilled, (state: SpecialistsPageState, action) => {
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          state.data = action.payload;
        }
      });
  },
});

const specialistsReducer = specialistsPageSlice.reducer;

export const {
  setInputHiddenId,
  setInputEmail,
  setInputStudioName,
  setInputName,
  setInputDescription,
  openSpecialistDialogAddEdit,
  closeSpecialistDialogAddEdit,
  closeSpecialistDialogDelete,
  openSpecialistDialogDelete,
} = specialistsPageSlice.actions;

export default specialistsReducer;
