import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const DialogSuccessPasswordChanged = (props: { open: boolean }) => {
  return (
      <Dialog open={props.open}>
        <DialogTitle>Information</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={ () => { document.location.href= '/logout'; } }
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography>
            Your password successfully changed.
          </Typography>
        </DialogContent>
      </Dialog>
  );
}
