import { ApiService } from '../api/api.service';
import { IGetListRequest, IGetListResponse } from '../base/base.types';
import BaseService from '../base/base.service';
import { CreateSubscription, IGetActive, SubscriptionDTO } from './subscriptions.types';

class SubscriptionsService extends BaseService<SubscriptionDTO, CreateSubscription, Partial<SubscriptionDTO>> {
  readonly url = 'subscriptions';
  // @ts-ignore
  public override async getList(params: IGetListRequest): Promise<IGetListResponse<SubscriptionDTO>> {
    const response = await ApiService.get<IGetListResponse<SubscriptionDTO>>(this.url); // try / catch
    return response.data;
  }

  public async active(params: IGetActive): Promise<SubscriptionDTO[]> {
    const queryString = Object.entries(params).map(([key, value]) => key + '=' + value).join('&');
    const response = await ApiService.get<SubscriptionDTO[]>(this.url + '/active?' + queryString); // try / catch
    return response.data;
  }

  public async create(params: CreateSubscription): Promise<void> {
    await ApiService.post(this.url, params);
  }

  public async refund(id: number): Promise<void> {
    await ApiService.delete(`${this.url}/${id}`);
  }
}

const subscriptionsService = new SubscriptionsService();

export default subscriptionsService;
