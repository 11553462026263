import BaseService from '../base/base.service';
import { ApiService } from '../api/api.service';
import { Booking, CreateBooking } from './bookings.types';

class BookingsService extends BaseService<Booking> {
  readonly url = 'bookings';

  public async create(params: CreateBooking): Promise<void> {
    await ApiService.post(this.url, params);
  }
}

const bookingsService = new BookingsService();

export default bookingsService;

