import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import {
  closeServiceDialogAddEdit,
  fetchServices,
  ServicesPageState,
  setServiceInputDescription, setServiceInputGroupId,
  setServiceInputName
} from '../servicesPage.slice';
import servicesService from '../../../services/services/services.service';
import React, { useEffect } from 'react';
import { ServiceGroup } from '../../../services/serviceGroups/serviceGroups.types';
import { getServiceGroups } from '../../../store/dictionaries.selectors';
import { fetchServiceGroupsDict } from '../../../store/dictionaries.slice';

export const ServiceDialogAddEdit = () => {
  const dispatch: ThunkDispatch<ServicesPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { description, name, id, groupId },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.services.services.dialogs.addEdit);

  const serviceGroups = useSelector(getServiceGroups);

  const dialogClose = () => dispatch(closeServiceDialogAddEdit({}));

  useEffect(() => {
    if (serviceGroups.length === 0) {
      dispatch(fetchServiceGroupsDict());
    }
  }, [dispatch]);

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;
    const body = { name, description, serviceGroupIds: [groupId] };

    try {
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await servicesService.add(body);
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await servicesService.edit(id, body);
      }

      dispatch(fetchServices());
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} service: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Add service' : 'Edit service'}</DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: 500 }}>
          <InputLabel id="group-label">Group</InputLabel>
          <Select
              label="Group"
              labelId="group-label"
              fullWidth
              value={groupId !== -1 ? groupId : '' }
              onChange={(e) => dispatch(setServiceInputGroupId(e.target.value))}
          >
            {serviceGroups.map((group: ServiceGroup) => (
              <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
            ))}
          </Select>
          <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => dispatch(setServiceInputName(e.target.value))}
              sx={{ marginBottom: 1, marginTop: 1 }}
          />
          <TextField
              multiline={true}
              rows={6}
              label="Description"
              variant="outlined"
              fullWidth
              onChange={(e) => dispatch(setServiceInputDescription(e.target.value))}
              value={description}
              sx={{ marginTop: 1 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Add service'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

