import { ApiService } from '../api/api.service';
import { Company } from '../../pages/company/company.types';
import { CompanyDTO } from './company.types';

class CompanyService {
  public static async get(): Promise<CompanyDTO> {
    const response = await ApiService.get<CompanyDTO>(`company`);

    return response.data;
  }

  public static async update(data: Partial<Company>): Promise<void> {
    await ApiService.put(`company`, data);
  }
}

export default CompanyService;
