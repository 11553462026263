import { Box, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import specialistsService from '../../services/specialists/specialists.service';
import React, { useEffect } from 'react';
import { Specialist } from '../../services/specialists/specialists.types';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
  fetchSpecialists,
  closeSpecialistDialogDelete,
  openSpecialistDialogAddEdit,
  openSpecialistDialogDelete,
} from './specialistsPage.slice';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { SpecialistDialogAddEdit } from './components/SpecialistDialogAddEdit';
import { DialogDelete } from '../../components/DialogDelete';
import { InitialValues } from '../../types/types';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const SpecialistsPage = () => {
  const dispatch: ThunkDispatch<Specialist[], any, AnyAction> = useDispatch();

  const {
    data: specialists,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.specialists);

  const { studioId } = useSelector((state: RootState) => state.app);

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await specialistsService.delete(deletingId, studioId);
        dispatch(fetchSpecialists(studioId));
        dispatch(closeSpecialistDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Specialist successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete specialist: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'email',
    headerName: 'E-mail',
    width: 200
  },{
    field: 'name',
    headerName: 'Name',
    width: 200
  },{
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
  },{
    field: 'status',
    headerName: 'Status',
    width: 150
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openSpecialistDialogAddEdit({
          mode: 'edit',
          id: params.row.id,
          name: params.row.name,
          description: params.row.description,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openSpecialistDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    if (studioId !== InitialValues.UNKNOWN_ID) {
      dispatch(fetchSpecialists(studioId));
    } else {
      // todo: clear
    }

  }, [dispatch, studioId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
            variant="contained"
            sx={{ marginBottom: 1 }}
            onClick={ () => dispatch(openSpecialistDialogAddEdit({ mode: 'add' })) }
            startIcon={<AddCircleIcon />}
        >Invite specialist</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this specialist?"
        onClose={() => dispatch(closeSpecialistDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <SpecialistDialogAddEdit />

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        rows={specialists}
        hideFooter={true}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default SpecialistsPage;
