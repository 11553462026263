import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SubscriptionWithClient } from '../../pages/subscriptions/subscriptions.types';
import { Dispatch, SetStateAction } from 'react';
import SubscriptionsService from '../../services/subscriptions/subscriptions.service';
import { toggleDialogInfo } from '../dialog/dialogInfo.slice';
import { useDispatch } from 'react-redux';

const SubscriptionDialogRefund = ({
  isOpen,
  setOpened,
  refundSubscription,
  callback,
}: {
  isOpen: boolean,
  refundSubscription: SubscriptionWithClient,
  setOpened: Dispatch<SetStateAction<boolean>>,
  callback: () => any,
}) => {
  const dispatch = useDispatch();

  const dialogClose = () => {
    setOpened(false);
  };

  const handleSubmit = async () => {
    try {
      await SubscriptionsService.refund(refundSubscription.id);
      setOpened(false);
      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: 'Subscription refunded',
      }));
      callback();
    } catch (err: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while refund subscription: ' + err.message,
      }));
    }
  };

  return (
      <Dialog open={isOpen} onClose={dialogClose} sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}>
        <DialogTitle>Refund subscription</DialogTitle>
        <DialogContent>
          Refund subscription for client {refundSubscription?.client.name + ' ' + refundSubscription?.client.surname}
          with amount {refundSubscription?.price} {refundSubscription?.currency}?
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Refund subscription
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default SubscriptionDialogRefund;
