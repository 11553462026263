import { ApiService } from '../api/api.service';

class TimezonesService {
  public static async getList(): Promise<string[]> {
    const response = await ApiService.get<string[]>(`helpers/timezones`);

    return response.data
  }
}

export default TimezonesService;
