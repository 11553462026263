import { ApiService } from '../api/api.service';
import { EditMyProfile } from './myProfile.types';
import { MyProfile } from '../../pages/myProfile/myProfile.types';

class MyProfileService {
  url: string = 'profile';

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await ApiService.put(this.url + `/password/change`, {
      oldPassword, newPassword,
    });
  }

  public async edit(params: EditMyProfile): Promise<void> {
    await ApiService.put(this.url + '', params);
  }

  public async getOne(): Promise<MyProfile> {
    const response = await ApiService.get<MyProfile>(this.url);
    return response.data;
  }
}

const myProfileService = new MyProfileService();

export default myProfileService;
