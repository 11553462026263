import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import {
  closeUserDialogAddEdit, fetchUsers,
  setInputName,
  setInputRole,
  setInputStatus,
  UsersPageState
} from '../usersPage.slice';
import UsersService from '../../../services/users/users.service';

export const UserDialogAddEdit = () => {
  const dispatch: ThunkDispatch<UsersPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { email, name, status, role, id },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.users.dialogs.addEdit);

  const dialogClose = () => dispatch(closeUserDialogAddEdit({}));

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;
    const body = { name, role, status };

    try {
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await UsersService.add({ email, role, name });
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await UsersService.edit(id as number, body);
      }

      dispatch(fetchUsers());
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} room: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Add user' : 'Edit user'}</DialogTitle>
      <DialogContent>
        <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => dispatch(setInputName(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
        />
        {mode === 'edit' && (
        <TextField
            multiline={true}
            rows={6}
            label="Status"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputStatus(e.target.value))}
            value={status}
            sx={{ marginTop: 1 }}
        />
        )}
        <TextField
            multiline={true}
            rows={6}
            label="Role"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputRole(e.target.value))}
            value={role}
            sx={{ marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Add user'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

