export const timeGreaterThenTime = (time1: string, time2: string, orEqual: boolean = false) => {
  const [h1, m1] = time1.split(':');
  const [h2, m2] = time2.split(':');

  if (!h1 || !h2 || !m1 || !m2) {
    throw new Error("Invalid time");
  }

  const d1 = new Date();
  d1.setHours(Number(h1),Number(m1));

  const d2 = new Date();
  d2.setHours(Number(h2),Number(m2));

  if (orEqual) {
    return d1 >= d2;
  }

  return d1 > d2;
}
