export type GetList<T> = {
  elements: T[];
  total: number;
}

export type IsoDateString = string;

export enum InitialValues {
  UNKNOWN_ID = -1,
  NO_OPTIONS = -2,
}
