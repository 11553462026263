import { Box, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { fetchSubscriptions } from './subscriptionsPage.slice';
import { Pricelist } from '../../services/pricelist/pricelist.types';
import currencySign from '../../utils/currencySign';
import { fetchServiceGroupsDict } from '../../store/dictionaries.slice';
import { Subscription } from './subscriptions.types';
import { setBackPage, setClientId } from '../clientCard/clientCardPage.slice';
import { setCurrentPage } from '../applicationPage.slice';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { setPage } from '../clients/clientsPage.slice';
import { DateTime } from 'luxon';
import { getServiceGroups } from '../../store/dictionaries.selectors';
import GridBoolean from '../../utils/renderers/GridBoolean';

const SubscriptionsPage = () => {
  const dispatch: ThunkDispatch<Subscription[], any, AnyAction> = useDispatch();
  const navigate = useNavigate();

  const { data: subscriptions, rowCount, paginationModel } = useSelector((state: RootState) => state.subscriptions);
  const serviceGroups = useSelector(getServiceGroups);

  useEffect(() => {
    (async() => {
      try {
        await dispatch(fetchServiceGroupsDict());
        await dispatch(fetchSubscriptions());
      }
      catch (err: any) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: err.message,
        }));
      }
    })();
  }, [dispatch]);

  const handlePageChange = (props: any) => {
    dispatch(setPage(props.page));
  };

  const openClientCard = (clientId: number) => {
    dispatch(setClientId(clientId));
    dispatch(setCurrentPage('client-card'));
    dispatch(setBackPage('subscriptions'));
    navigate('#client-card');
  };

  const columnsSubscriptions: GridColDef[] = [{
    field: 'view',
    headerName: 'View',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <IconButton onClick={() => openClientCard(params.row.clientId)}>
        <AccountBoxIcon />
      </IconButton>
    ),
  },{
    field: 'id',
  }, {
    field: 'clientId',
    headerName: 'Client',
    flex: 1
  }, {
    field: 'serviceName',
    headerName: 'Service',
    flex: 1,
    renderCell: (params: GridRenderCellParams<Subscription>) => {
      return serviceGroups.find((group) => group.id === params.row.serviceGroupId)?.name ?? '';
    }
  },{
    field: 'period',
    headerName: 'Period',
    width: 200,
    renderCell: (params: GridRenderCellParams<Subscription>) => {
      const end = params.row.dateEnd
        ? DateTime.fromISO(params.row.dateEnd).toFormat('dd.MM.yyyy')
        : 'unlimited';
      return `${DateTime.fromISO(params.row.dateStart).toFormat('dd.MM.yyyy')} - ${end}`;
    }
  },{
    field: 'utilization',
    headerName: 'Utilization',
    width: 100,
    renderCell: (r) => r.row.limit + ' / ' + r.row.totalVisits,
  },{
    field: 'enabled',
    headerName: 'Enabled',
    width: 80,
    renderCell: GridBoolean,
  },{
    field: 'price',
    headerName: 'Price',
    width: 100,
    renderCell: (params: GridRenderCellParams<Pricelist>) => {
      return params.row.price + ' ' + currencySign(params.row.currency);
    }
  },{
    field: 'createdAt',
    headerName: 'Date',
    width: 100,
    renderCell: (params: GridRenderCellParams<Subscription>) => {
      return params.row.createdAt ? DateTime.fromISO(params.row.createdAt).toFormat('dd.MM.yyyy') : '';
    }
  }];

  return <Box sx={{ height: 'calc(100vh - 55px)' }}>
    <DataGrid
      sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          }
        }
      }}
      columns={columnsSubscriptions}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePageChange}
      rows={subscriptions}
      rowCount={rowCount}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      filterMode='server'
      paginationMode='server'
    />
  </Box>;
};

export default SubscriptionsPage;
