import BaseService from '../base/base.service';
import { ClientGroupCreateDTO, ClientGroupDTO, ClientGroupEditDTO } from './clientGroups.types';
import { ApiService } from '../api/api.service';

class ClientGroupsService extends BaseService<ClientGroupDTO, ClientGroupCreateDTO, ClientGroupEditDTO> {
  readonly url = 'client-groups';

  // @ts-ignore
  public override async getList(studioId: number): Promise<ClientGroup[]> {
    const response = await ApiService.get<ClientGroupDTO[]>(`${this.url}?studioId=${studioId}`);
    return response.data;
  }
}

const serviceGroupsService = new ClientGroupsService();

export default serviceGroupsService;
