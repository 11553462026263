import { IScheduleAdded, Schedule } from './schedulePage.types';
import { DateTime, Interval } from 'luxon';

export const checkIfConflicts = (schedule: Schedule[], record: IScheduleAdded): boolean => {
  const start = record.startDate instanceof Date ? DateTime.fromJSDate(record.startDate) : DateTime.fromISO(record.startDate);
  const end = record.endDate instanceof Date ? DateTime.fromJSDate(record.endDate) : DateTime.fromISO(record.endDate);
  const recordInterval = Interval.fromDateTimes(start, end);

  return schedule.some((r) =>
    Interval
      .fromDateTimes(DateTime.fromISO(r.startDate), DateTime.fromISO(r.endDate))
      .overlaps(recordInterval)
  );
};
