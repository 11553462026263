import { Box, Container } from '@mui/material';
import React from 'react';
import logo from '../../assets/images/main-logo.png';

const Page404 = () => {
  return (
    <Container sx={{
      height: '100vh', // Занимает 100% высоты экрана
      display: 'flex',
      justifyContent: 'center', // Центрируем по горизонтали
      alignItems: 'center', // Центрируем по вертикали
    }} maxWidth={false}>
      <Box sx={{
        width: 720,
        height: 466,
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '32px'
      }}>
        <Box sx={{
          textAlign: 'center'
        }}>
          <img src={logo} alt="logo" width={234}/>
        </Box>
        <Box sx={{
          textAlign: 'center',
          marginTop: 6,
          fontSize: '150%'
        }}>Error 404: Page not found</Box>
      </Box>
    </Container>
  );
}

export default Page404;
