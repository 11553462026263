import { Pricelist, PricelistCreate, PricelistEdit } from './pricelist.types';
import BaseService from '../base/base.service';
import { ApiService } from '../api/api.service';

class PricelistService extends BaseService<Pricelist, PricelistCreate, PricelistEdit> {
  readonly url = 'pricelists';

  // @ts-ignore
  public override async getList(studioId: number): Promise<Pricelist[]> {
    const response = await ApiService.get<Pricelist[]>(`pricelists?studioId=${studioId}`);

    return response.data;
  }
}

const pricelistService = new PricelistService();

export default pricelistService;
