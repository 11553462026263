import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { GetList } from '../../types/types';
import { GridPaginationModel } from '@mui/x-data-grid';
import { Subscription } from './subscriptions.types';
import SubscriptionsService from '../../services/subscriptions/subscriptions.service';

export type SubscriptionsPageState = {
  rowCount: number,
  data: Subscription[],
  filter: string,
  paginationModel: GridPaginationModel;
};

const initialState: SubscriptionsPageState = {
  rowCount: 0,
  data: [],
  filter: '',
  paginationModel: {
    page: 0,
    pageSize: 50,
  }
};

export const fetchSubscriptions = createAsyncThunk<GetList<Subscription>>(
'services/fetchSubscriptions',
async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;

    const response = await SubscriptionsService.getList({
      page: state.subscriptions.paginationModel.page + 1,
      pageSize: state.subscriptions.paginationModel.pageSize,
    });
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch subscriptions');
  }
});

const subscriptionsPageSlice = createSlice<SubscriptionsPageState, SliceCaseReducers<SubscriptionsPageState>>({
  name: 'clients',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.paginationModel.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.fulfilled, (state: SubscriptionsPageState, action) => {
        state.data.length = 0;
        state.rowCount = action.payload.total;
        state.data = action.payload.elements;
      })
      .addCase(fetchSubscriptions.rejected, (state: SubscriptionsPageState, action) => {
        state.data.length = 0;
        throw new Error(action.payload as string);
      });
  },
});

const subscriptionsReducer = subscriptionsPageSlice.reducer;

export const {
  setPage,
} = subscriptionsPageSlice.actions;

export default subscriptionsReducer;
