import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
  loadClientData,
  setComment,
  setDuration,
  setEnd,
  setInputPrice,
  setInputPricelistId,
  setInputServiceGroupId,
  setPricelist,
  setPricelists,
  setStart,
  toggleDialogSubscriptionAdd
} from '../clientCardPage.slice';
import { useEffect } from 'react';
import { fetchServiceGroupsDict } from '../../../store/dictionaries.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import clientPhoto from '../../../assets/images/client.png';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import pricelistService from '../../../services/pricelist/pricelist.service';
import subscriptionsService from '../../../services/subscriptions/subscriptions.service';
import { Pricelist } from '../../../services/pricelist/pricelist.types';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { InitialValues } from '../../../types/types';
import { getServiceGroups } from '../../../store/dictionaries.selectors';
import currencySign from '../../../utils/currencySign';

export const SubscriptionAddDialog = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const {
    isOpen,
    inputs: {
      serviceGroupId,
      price,
      start,
      end,
      comment,
      pricelistId,
      pricelists,
    },
    meta: {
      durationUnit, durationValue,
    }
  } = useSelector((state: RootState) => state.clientCard.dialogs.subscriptionAdd);
  const { name, phone, email, clientId } = useSelector((state: RootState) => state.clientCard);
  const { currency } = useSelector((state: RootState) => state.pricelist);
  const { studioId } = useSelector((state: RootState) => state.app);
  const serviceGroups = useSelector(getServiceGroups);

  useEffect(() => {
    (async () => {
      if (serviceGroupId === InitialValues.UNKNOWN_ID) return; // todo: очистка

      const allPricelists = await pricelistService.getList(studioId);
      dispatch(setPricelists(allPricelists.filter((price) => price.serviceGroupId === serviceGroupId)));
    })();
  }, [serviceGroupId, dispatch]);

  useEffect(() => {
    (async () => {
      if (pricelistId === InitialValues.UNKNOWN_ID) return;

      const price = pricelists.find((pricelist: Pricelist) => pricelist.id === pricelistId);

      if (price) {
        dispatch(setPricelist(price.price));
        dispatch(setDuration(price.rules.duration));
      } else {
        console.error('Pricelist not found');
      }
    })();
  }, [pricelistId, dispatch]);

  useEffect(() => {
    startDateOnChange(DateTime.fromISO(start)); // чтобы пересчитать дату окончания
  }, [durationUnit, durationValue]);

  const dialogClose = () => dispatch(toggleDialogSubscriptionAdd(false));

  const startDateOnChange = (dateTime: DateTime | null) => {
    if (dateTime) {
      dispatch(setStart(dateTime.toFormat('yyyy-MM-dd')));

      if (durationUnit === 'unlimited') {
        dispatch(setEnd(null));
      } else {
        const end = dateTime.plus({ [durationUnit]: durationValue });
        dispatch(setEnd(end.toFormat('yyyy-MM-dd')));
      }
    } else {
      dispatch(setStart(''));
    }
  };

  const serviceGroupOnChange = (_: unknown, newInputValue: { id: number, label: string } | null) => {
    dispatch(setInputServiceGroupId(newInputValue?.id || ''));
    dispatch(setInputPricelistId(InitialValues.UNKNOWN_ID));
    dispatch(setInputPrice(''));
  };

  const handleSubmit = async () => {
    const handleError = (message: string) => {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while creating subscription: ${message}`,
      }));
    };

    try {
      const pricelist = pricelists.find((pricelist) => pricelist.id === pricelistId);

      if (!pricelist) {
        handleError('Pricelist not found');
        return;
      }

      await subscriptionsService.create({
        clientId,
        pricelistId: pricelist.id,
        price: pricelist.price,
        dateStart: start,
        comment,
      });

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully created.`,
      }));
      dialogClose();
      dispatch(loadClientData(clientId));
    } catch(err: any) {
      handleError(err.message);
    }
  };

  useEffect(() => {
    if (serviceGroups.length === 0) {
      dispatch(fetchServiceGroupsDict());
    }
  }, [dispatch, serviceGroups]);

  return (
    <Dialog open={isOpen} onClose={dialogClose}>
      <DialogTitle>Add subscription</DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px',
          border: '2px solid #D9D9D9',
          borderRadius: '16px',
          padding: '20px'
        }}>
          <Box sx={{ height: '120px', marginRight: '10px' }}>
            <img src={clientPhoto} height={'120px'} />
          </Box>
          <Box sx={{
                display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', width: '100%'
          }}>
            <Box sx={{ width: '100%' }}>
              {name}
              <br/>
              {phone}
              <br/>
              {email}
            </Box>
            <Box sx={{ marginLeft: 'auto' }}>
              ID:&nbsp;{clientId}
            </Box>
          </Box>
        </Box>

        <Autocomplete
          options={serviceGroups.map((group) => ({
            id: group.id,
            label: group.name,
          }))}
          fullWidth
          inputValue={serviceGroups.find((group) => group.id === serviceGroupId)?.name || ''}
          onChange={serviceGroupOnChange}
          sx={{ marginBottom: 1, marginTop: 1 }}
          renderInput={(params) => <TextField
              {...params}
              label="Service group"
              variant="outlined"
          />}
        />

        <Autocomplete
          options={pricelists.map((price: Pricelist) => {
            let duration = '';

            if (price.rules.duration?.unit) {
              if (price.rules.duration.unit === 'unlimited') {
                duration = ' (unlimited)';
              } else {
                duration = ` (${price.rules.duration.value} ${price.rules.duration.unit}s)`;
              }
            }

            return {
              id: price.id,
              label: price.limit.toString() + duration,
            };
          })}
          fullWidth
          onChange={(_, newInputValue) => dispatch(setInputPricelistId(newInputValue ? newInputValue.id : InitialValues.UNKNOWN_ID ))}
          sx={{ marginBottom: 1, marginTop: 1 }}
          renderInput={(params) => <TextField
              {...params}
              label="Limit"
              variant="outlined"
          />}
        />

        <TextField
          label="Price"
          variant="outlined"
          fullWidth
          disabled={true}
          value={price}
          sx={{ marginTop: 1 }}
          InputProps={{ endAdornment: <InputAdornment position="end">{currencySign(currency)}</InputAdornment> }}
          inputProps={{ min: 0.1 }}
        />

        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"ru-RU"}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <DatePicker
                label="Subscription start"
                value={DateTime.fromISO(start).toJSDate()}
                onChange={startDateOnChange}
                renderInput={(props) => <TextField {...props} sx={{
                  marginTop: '20px',
                  marginRight: '10px',
                  width: '50%'
                }} />}
            />
            <DatePicker
                label="Subscription end"
                value={end}
                disabled={true}
                onChange={(dateTime: DateTime | null) => dispatch(setEnd(dateTime))}
                renderInput={(props) => <TextField {...props} sx={{
                  marginTop: '20px',
                  width: '50%'
                }} />}
            />
          </Box>
        </LocalizationProvider>

        <TextField
            label="Comment"
            variant="outlined"
            fullWidth
            multiline={true}
            rows={4}
            onChange={(e) => dispatch(setComment(e.target.value))}
            value={comment}
            sx={{ marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
};
