import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { MatrixResources } from './matrixPage.types';
import MatrixService from '../../services/matrix/matrix.service';
import { MatrixCreate, MatrixEdit, MatrixView } from '../../services/matrix/matrix.types';
import { fetchRoomsDict, prepareResources } from '../../store/dictionaries.slice';
import { timeToIsoDateString } from '../../utils/timeToIsoDateString';
import { removeUndefinedFromObject } from '../../utils/removeUndefinedFromObject';
import { RoomDict } from '../../services/rooms/rooms.types';
import { InitialValues } from '../../types/types';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export type MatrixPageState = {
  data: MatrixView[],
  rooms: RoomDict[],
  roomId: number,
  studioId: number, // используется для определения справочника тренеров. является копией app->studioId, но устанавливается после обновления данных
  resources: MatrixResources,
  disableSaveButton: boolean,
  showAddEditMatrixForm: boolean;
  editingAppointment: AppointmentModel | undefined;
  appointmentChanges: Partial<MatrixView & AppointmentModel>;
  appointmentData: Partial<MatrixView & AppointmentModel>;
};

const initialState: MatrixPageState = {
  data: [],
  rooms: [],
  roomId: InitialValues.UNKNOWN_ID,
  studioId: InitialValues.UNKNOWN_ID,
  showAddEditMatrixForm: false,
  editingAppointment: undefined,
  appointmentChanges: {},
  appointmentData: {},
  disableSaveButton: false,
  resources: [{
    fieldName: 'specialistId',
    title: 'Specialist',
    instances: [],
  }, {
    fieldName: 'serviceId',
    title: 'Service',
    instances: [],
  }, {
    fieldName: 'limit',
    title: 'Limit',
    instances: Array.from({ length: 20 }).map((_, index) => ({
      id: index + 1,
      text: (index + 1).toString(),
    })),
  }, {
    fieldName: 'day',
    title: 'Day',
    instances: days.map((day, index) => ({
      id: index + 1,
      text: day,
    })),
  }],
};

export const fetchMatrix = createAsyncThunk<MatrixView[], { roomId: number }>(
  'matrix/fetchMatrix',
  async ({ roomId }, { rejectWithValue }) => {
  try {
    const response = await MatrixService.getList(roomId);

    const matrix = response.map((record) => ({
      id: record.id,
      specialistId: record.specialistId,
      serviceId: record.serviceId,
      day: record.day,
      limit: record.limit || 1,
      startDate: timeToIsoDateString(record.start),
      endDate: timeToIsoDateString(record.end),
    })).sort((a, b) => a.day - b.day);

    return matrix;
  } catch (error: any) {
    return rejectWithValue('Error while fetch matrix');
  }
});

const matrixPageSlice = createSlice<MatrixPageState, SliceCaseReducers<MatrixPageState>>({
  name: 'matrix',
  initialState,
  reducers: {
    clearMatrix: (state: MatrixPageState) => {
      state.data = [];
    },
    setEditingAppointment:(state: MatrixPageState, action: PayloadAction<AppointmentModel>) => {
      state.editingAppointment = action.payload;
      state.appointmentData = action.payload as any;
    },
    setAppointmentChanges: (state: MatrixPageState, action: PayloadAction<AppointmentModel>) => {
      state.appointmentChanges = action.payload as any;
      Object.assign(state.editingAppointment || {}, action.payload);
    },
    setShowAddEditMatrixForm:(state: MatrixPageState, action: PayloadAction<boolean>) => {
      state.showAddEditMatrixForm = action.payload;
    },
    setRoomId: (state: MatrixPageState, action: PayloadAction<number>) => {
      state.roomId = action.payload;
    },
    setMatrixStudioId: (state: MatrixPageState, action: PayloadAction<number>): void => {
      state.studioId = action.payload;
    },
    setDisableSaveButton: (state: MatrixPageState, action: PayloadAction<boolean>) => {
      state.disableSaveButton = action.payload;
    },
    // changeMatrixRecord: (state: MatrixPageState, action: PayloadAction<{ id: number, changed: Partial<MatrixEdit>}>) => {
    //   const record = state.data.find((r) => r.id === action.payload.id);
    //
    //   if (!record) {
    //     console.error('Record not found');
    //     return;
    //   }
    //
    //   const changedData = removeUndefinedFromObject({
    //     ...action.payload.changed,
    //     // startDate: timeToIsoDateString(action.payload.changed.start),
    //     // endDate: timeToIsoDateString(action.payload.changed.end),
    //     // start: undefined,
    //     // end: undefined,
    //   });
    //
    //   Object.assign(record, changedData);
    // },
    addMatrixRecord: (state: MatrixPageState, action: PayloadAction<MatrixCreate & { id: number; }>) => {
      const addedData = removeUndefinedFromObject({
        ...action.payload,
        startDate: timeToIsoDateString(action.payload.start),
        endDate: timeToIsoDateString(action.payload.end),
        start: undefined,
        end: undefined,
      });

      state.data.push(addedData);
    },
    deleteMatrixRecord: (state: MatrixPageState, action: PayloadAction<number>) => {
      const recordIndex = state.data.findIndex((record) => record.id === action.payload);

      if (recordIndex) {
        state.data.splice(recordIndex, 1);
      } else {
        console.error('Matrix with ' + action.payload + ' not found');
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomsDict.fulfilled, (state: MatrixPageState, action) => {
        state.rooms = action.payload;
      })
      .addCase(fetchMatrix.fulfilled, (state: MatrixPageState, action) => {
        state.data.length = 0;
        state.data.push(...action.payload);
      })
      .addCase(prepareResources.fulfilled, (state: MatrixPageState, action) => {
        state.resources[0].instances = action.payload.specialists.map((specialist) => ({
          id: specialist.id,
          text: specialist.name,
        }));

        state.resources[1].instances = action.payload.services.map((service) => ({
          id: service.id,
          text: service.name,
        }));
      })
  },
});

const matrixReducer = matrixPageSlice.reducer;

export const {
  clearMatrix,
  setRoomId,
  setDisableSaveButton,
  addMatrixRecord,
  deleteMatrixRecord,
  setShowAddEditMatrixForm,
  setEditingAppointment,
  setAppointmentChanges,
  setMatrixStudioId,
} = matrixPageSlice.actions;

export default matrixReducer;
