import { ApiService } from '../api/api.service';
import { Message, SendMessageResponse } from './support.types';

class SupportService {
  public static async sendMessage(message: Message): Promise<SendMessageResponse> {
    try {
      await ApiService.post('support/message', message);
      return true;
    } catch(e: any) {
     return {
       error: e.message,
     };
    }
  }
}

export default SupportService;
