import { ApiService } from '../api/api.service';

abstract class BaseService<T extends Record<string, any>, TCreate extends Record<string, any> = T, TEdit extends Record<string, any> = TCreate> {
  readonly abstract url: string;

  public async add(entity: TCreate): Promise<T> {
    const response = await ApiService.post(this.url, entity);
    return response.data;
  }

  public async getList(): Promise<T[]> {
    const response = await ApiService.get<T[]>(this.url); // try / catch
    return response.data;
  }

  public async edit(id: number, data: TEdit): Promise<void> {
    const response = await ApiService.put(`${this.url}/${id}`, data);
    return response.data;
  }

  public async delete(id: number): Promise<boolean> {
    const response = await ApiService.delete(`${this.url}/${id}`);
    return response.data;
  }
}

export default BaseService;
