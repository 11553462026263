import { Matrix, MatrixCreate, MatrixEdit } from './matrix.types';
import { ApiService } from '../api/api.service';

class MatrixService {
  public static async add(data: MatrixCreate): Promise<void> {
    await ApiService.post(`matrices`, data);
  }

  public static async delete(id: number): Promise<void> {
    await ApiService.delete(`matrices/${id}`);
  }

  public static async edit(id: number, data: MatrixEdit): Promise<void> {
    await ApiService.put(`matrices/${id}`, data);
  }

  public static async getList(roomId: number): Promise<Matrix[]> {
    const response = await ApiService.get<Matrix[]>(`matrices?roomId=${roomId}`);

    return response.data;
  }
}

export default MatrixService;
