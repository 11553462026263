import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { InitialValues } from '../types/types';

export type ApplicationPageState = {
  currentPage: string;
  backPage: string | null;
  studioId: number;
  startHours: number,
  endHours: number,
};

const initialState: ApplicationPageState = {
  currentPage: '',
  studioId: InitialValues.UNKNOWN_ID,
  backPage: null,
  startHours: 10,
  endHours: 23,
};

const applicationPageSlice = createSlice<ApplicationPageState, SliceCaseReducers<ApplicationPageState>>({
  name: 'services',
  initialState,
  reducers: {
    setWorkingTime: (state, action: PayloadAction<{ start: string, end: string }>) => {
      const [startHour, startMinute] = action.payload.start.split(':');
      const [endHour, endMinute] = action.payload.end.split(':');
      state.startHours = Number(startHour) + Number(startMinute) / 60;
      state.endHours = Number(endHour) + Number(endMinute) / 60;
    },
    setBackPage: (state, action: PayloadAction<string | null>) => {
      state.backPage = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    setStudioId: (state, action: PayloadAction<number>) => {
      state.studioId = action.payload;
    },
  },
});

const applicationPageReducer = applicationPageSlice.reducer;

export const {
  setCurrentPage,
  setBackPage,
  setStudioId,
  setWorkingTime,
} = applicationPageSlice.actions;

export default applicationPageReducer;
