import { timeGreaterThenTime } from './timeGreaterThenTime';
import { IsoDateString } from '../types/types';
import { timeIsoStringToTime } from './timeIsoStringToTime';

export interface ITimeRecord {
  id: number;
  day: number;
  startDate: IsoDateString;
  endDate: IsoDateString;
}

export const scheduleRecordsAreCross = (record1: ITimeRecord, record2: ITimeRecord) => {

  if (record1.id === record2.id) return false; // the same record

  try {
    const [start1, end1] = [timeIsoStringToTime(record1.startDate) as string, timeIsoStringToTime(record1.endDate) as string];
    const [start2, end2] = [timeIsoStringToTime(record2.startDate) as string, timeIsoStringToTime(record2.endDate) as string];

    return record1.day === record2.day && !(
        timeGreaterThenTime(start1, end2, true) ||
        timeGreaterThenTime(start2, end1, true)
    );
  }
  catch (e) {
    console.error(e);
    return false;
  }

}
