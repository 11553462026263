import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { RootState } from '../../../store/store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import {
  closeDialogClientCreate,
  setEmailDialogCreate, setInstagramDialogCreate,
  setNameDialogCreate,
  setPhoneDialogCreate,
  setSurnameDialogCreate, setTelegramDialogCreate, setWhatsAppDialogCreate
} from '../clientsPage.slice';
import ClientsService from '../../../services/clients/clients.service';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { ClientCreate } from '../../../services/clients/clients.types';

export const ClientCreateDialog = (props: { successCallback: () => void }) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const {
    openDialog,
    email,
    name,
    surname,
    phone,
    instagram,
    telegram,
    whatsapp,
  } = useSelector((state: RootState) => state.clients.dialogs.create);
  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => {
    dispatch(closeDialogClientCreate({}));
  };

  const handleSubmit = async () => {
    try {
      const data = _.omitBy({
        email,
        name,
        surname,
        phoneNumber: phone,
        instagram: instagram.length > 0 ? instagram : undefined,
        telegram: telegram.length > 0 ? telegram : undefined,
        whatsapp: whatsapp.length > 0 ? whatsapp : undefined,
        studioId,
      }, _.isUndefined) as ClientCreate;

      await ClientsService.create(data);

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully created.`,
      }));
      dialogClose();
      props.successCallback();
    } catch(err: unknown) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while check client: ${(err instanceof Error && err?.message) || err}`,
      }));
    }
  };

  return (
    <Dialog open={openDialog} onClose={dialogClose}>
      <DialogTitle>Create client</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => dispatch(setNameDialogCreate(e.target.value))}
          sx={{ marginTop: 1, width: '300px' }}
        />
        <TextField
            label="Surname"
            variant="outlined"
            value={surname}
            onChange={(e) => dispatch(setSurnameDialogCreate(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
        />
        <TextField
            label="E-mail"
            variant="outlined"
            disabled={true}
            value={email}
            onChange={(e) => dispatch(setEmailDialogCreate(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
        />
        <TextField
            label="Phone"
            variant="outlined"
            value={phone}
            onChange={(e) => dispatch(setPhoneDialogCreate(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
        />
        <TextField
            label="Instagram"
            variant="outlined"
            value={instagram}
            onChange={(e) => dispatch(setInstagramDialogCreate(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
        />
        <TextField
            label="Telegram"
            variant="outlined"
            value={telegram}
            onChange={(e) => dispatch(setTelegramDialogCreate(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
        />
        <TextField
            label="WhatsApp"
            variant="outlined"
            value={whatsapp}
            onChange={(e) => dispatch(setWhatsAppDialogCreate(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create client
        </Button>
      </DialogActions>
    </Dialog>
  );
};

