import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type ForgotPasswordState = {
  step: 1 | 2,
};

const initialState: ForgotPasswordState = {
  step: 1,
};

const forgotPasswordPageSlice = createSlice<ForgotPasswordState, SliceCaseReducers<ForgotPasswordState>>({
  name: 'forgot-password',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<1 | 2>) => {
      state.step = action.payload;
    },
  },
});

const forgotPasswordReducer = forgotPasswordPageSlice.reducer;

export const {
  setStep,
} = forgotPasswordPageSlice.actions;

export default forgotPasswordReducer;
