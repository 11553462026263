import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import {
  closeRoomDialogAddEdit, fetchRooms,
  RoomsPageState,
  setInputDescription,
  setInputName,
} from '../roomsPage.slice';
import roomsService from '../../../services/rooms/rooms.service';

export const RoomDialogAddEdit = () => {
  const dispatch: ThunkDispatch<RoomsPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { description, name, id },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.rooms.dialogs.addEdit);
  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => dispatch(closeRoomDialogAddEdit({}));

  const handleSubmit = async (mode: 'add' | 'edit') => {
    let textAction, textActionPast;

    try {
      if (!studioId) {
        throw new Error(`Studio not found`);
      }

      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await roomsService.add({
          name, description, studioId,
        });
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await roomsService.edit(id as number, {
          name, description,
        });
      }

      dispatch(fetchRooms(studioId));
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} room: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <DialogTitle>{mode === 'add' ? 'Add room' : 'Edit room'}</DialogTitle>
      <DialogContent>
        <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => dispatch(setInputName(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
        />
        <TextField
            multiline={true}
            rows={6}
            label="Description"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputDescription(e.target.value))}
            value={description}
            sx={{ marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(mode)} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Add room'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

