import { createSelector } from "reselect";
import { ServiceGroup } from '../services/serviceGroups/serviceGroups.types';
import { RootState } from './store';
import { Service } from '../services/services/services.types';

export const getServiceGroupsWithDeleted = (state: RootState) => state.dictionaries.serviceGroups;

export const getServiceGroups = createSelector(
    [getServiceGroupsWithDeleted],
    (serviceGroups) => serviceGroups.filter((serviceGroup: ServiceGroup) => !serviceGroup.deletedAt),
);

export const getServicesWithDeleted = (state: RootState) => state.dictionaries.services;

export const getServices = createSelector(
    [getServicesWithDeleted],
    (services) => services.filter((service: Service) => !service.deletedAt),
);
