import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth/auth.service';
import { useEffect } from 'react';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AuthService.logout();
    navigate('/');
  }, [navigate]);

  return null;
}

export default LogoutPage;
