import { User, UserCreate } from './users.types';

class UsersService {
  public static async add(room: UserCreate): Promise<User> {
    //const response = await ApiService.post('users', room);
    const response = {
      data: {
        ...room,
        id: Math.round(Math.random() * 1000),
      }
    }
    return response.data as User;
  }

  public static async getList(): Promise<User[]> {
    //const response = await ApiService.get('rooms');
    const response: User[] = [{
      id: 1,
      name: 'Гога',
      email: 'goga@gmail.com',
      role: 'manager',
      status: 'active'
    }, {
      id: 2,
      name: 'Мариам (Я)',
      email: 'mariam@mail.ge',
      role: 'manager',
      status: 'active'
    }, {
      id: 3,
      name: 'Эмилия',
      email: 'e@gmail.com',
      role: 'manager',
      status: 'invited'
    }];

    return response;
  }

  public static async edit(id: number, { name, role }: {
    name: string,
    role: string,
  }): Promise<void> {
    // fetch backend
    return Promise.resolve();
  }

  public static async block(id: number): Promise<void> {
    // fetch backend
    return Promise.resolve();
  }

  public static async activate(id: number): Promise<void> {
    // fetch backend
    return Promise.resolve();
  }

  public static async delete(id: number): Promise<boolean> {
    // fetch backend
    return Promise.resolve(true);
  }
}

export default UsersService;
