import BaseService from '../base/base.service';
import { ServiceGroupCreateDTO, ServiceGroupDTO } from './serviceGroups.types';

class ServiceGroupsService extends BaseService<ServiceGroupDTO, ServiceGroupCreateDTO> {
  readonly url = 'service-groups';
}

const serviceGroupsService = new ServiceGroupsService();

export default serviceGroupsService;
