import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Company } from './company.types';
import CompanyService from '../../services/company/company.service';

export type CompanyPageState = {
  currency: string,
  name: string,
  description: string,
  code: string,
};

const initialState: CompanyPageState = {
  currency: '',
  name: '',
  description: '',
  code: '',
};

export const getCompany = createAsyncThunk<Company>(
    'matrix/getCompany',
    async (_, { rejectWithValue }) => {
      try {
        const response = await CompanyService.get();
        return response;
      } catch (error: any) {
        return rejectWithValue('Error while get company');
      }
    });

const companySlice = createSlice<CompanyPageState, SliceCaseReducers<CompanyPageState>>({
  name: 'company',
  initialState,
  reducers: {
    setInputName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setInputCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setInputDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.fulfilled, (state: CompanyPageState, action) => {
        Object.assign(state, action.payload);
      })
  },
});

const companyReducer = companySlice.reducer;

export const {
  setInputCurrency,
  setInputDescription,
  setInputName,
  setCode,
} = companySlice.actions;

export default companyReducer;
