import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ClientGroupsService from '../../services/clientGroups/clientGroups.service';
import { ClientGroup } from '../../services/clientGroups/clientGroups.types';

type DialogAddEditAction = {
  mode: 'add',
} | {
  mode: 'edit',
  id: number,
  name: string,
  description: string,
};

export type ClientGroupsPageState = {
  data: ClientGroup[],
  dialogs: {
    addEdit: {
      open: boolean;
      mode: 'add' | 'edit',
      success: boolean;
      inputs: {
        id: number | null;
        name: string;
        description: string;
      }
    },
    delete: {
      open: boolean;
      deletingId: number | null;
    },
  },
};

const initialState: ClientGroupsPageState = {
  data: [],
  dialogs: {
    addEdit: {
      open: false,
      mode: 'add',
      success: false,
      inputs: {
        id: null,
        name: '',
        description: '',
      }
    },
    delete: {
      open: false,
      deletingId: null,
    },
  },
};

export const fetchClientGroups = createAsyncThunk<ClientGroup[], number>(
'services/fetchClientGroups',
async (studioId, { rejectWithValue }) => {
  try {
    const response = await ClientGroupsService.getList(studioId);
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch services');
  }
});

const clientGroupsPageSlice = createSlice<ClientGroupsPageState, SliceCaseReducers<ClientGroupsPageState>>({
  name: 'ClientGroups',
  initialState,
  reducers: {
    clearClientGroups: (state) => {
      state.data = [];
    },
    closeClientGroupDialogAddEdit: (state) => {
      state.dialogs.addEdit.open = false;
    },
    openClientGroupDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.dialogs.addEdit.open = true;
      state.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.dialogs.addEdit.inputs, initialState.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.dialogs.addEdit.inputs, action.payload);
      }
    },
    closeClientGroupDialogDelete: (state) => {
      state.dialogs.delete.open = false;
    },
    openClientGroupDialogDelete: (state, action: PayloadAction<number>) => {
      state.dialogs.delete.deletingId = action.payload;
      state.dialogs.delete.open = true;
    },
    setInputId: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.id = action.payload;
    },
    setInputName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.name = action.payload;
    },
    setInputDescription: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.description = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientGroups.fulfilled, (state: ClientGroupsPageState, action) => {
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          state.data = action.payload;
        }
      });
  },
});

const clientGroupsReducer = clientGroupsPageSlice.reducer;

export const {
  clearClientGroups,
  setInputId,
  setInputStudioName,
  setInputName,
  setInputDescription,
  openClientGroupDialogAddEdit,
  closeClientGroupDialogAddEdit,
  openClientGroupDialogDelete,
  closeClientGroupDialogDelete,
} = clientGroupsPageSlice.actions;

export default clientGroupsReducer;
