import { Specialist, SpecialistEdit, SpecialistInvite } from './specialists.types';
import BaseService from '../base/base.service';
import { ApiService } from '../api/api.service';
import { IGetListResponse } from '../base/base.types';

class SpecialistsService extends BaseService<Specialist, SpecialistInvite, SpecialistEdit> {
  readonly url = 'specialists';

  // @ts-ignore
  public override async getList(studioId: number): Promise<Specialist[]> {
    const response = await ApiService.get<IGetListResponse<Specialist>>(this.url + '?studioId=' + studioId); // try / catch
    return response.data.elements;
  }

  // @ts-ignore
  public override async edit(id: number, studioId: number, data: TEdit): Promise<void> {
    const response = await ApiService.put(`${this.url}/${id}?studioId=${studioId}`, data);
    return response.data;
  }

  // @ts-ignore
  public override async delete(id: number, studioId: number): Promise<boolean> {
    const response = await ApiService.delete(`${this.url}/${id}?studioId=${studioId}`);
    return response.data;
  }
}

const specialistsService = new SpecialistsService();

export default specialistsService;
