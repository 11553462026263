import { ThunkDispatch } from '@reduxjs/toolkit';
import { Matrix } from '../../../services/matrix/matrix.types';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import React from 'react';
import CommandLayoutProps = AppointmentForm.CommandLayoutProps;
import { setShowAddEditMatrixForm } from '../matrixPage.slice';

export const AppointmentCommandLayoutComponent = (props: CommandLayoutProps) => {
  const dispatch: ThunkDispatch<Matrix[], { }, AnyAction> = useDispatch();

  return (
      <AppointmentForm.CommandLayout
          { ...props}
          onCancelButtonClick={() => dispatch(setShowAddEditMatrixForm(false))}
      />
  );
}
