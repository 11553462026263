import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import React from 'react';
import CommandLayoutProps = AppointmentForm.CommandLayoutProps;
import { setAddEditScheduleRecordForm } from '../schedulePage.slice';
import { Schedule } from '../schedulePage.types';
import { RootState } from '../../../store/store';

export const AppointmentCommandLayoutComponent = (props: CommandLayoutProps & { disableSaveButton: boolean }) => {
  const dispatch: ThunkDispatch<Schedule[], { roomId: number }, AnyAction> = useDispatch();

  const {
    editingScheduleRecord,
  } = useSelector((state: RootState) => state.schedule);

  const hideDeleteButton = !editingScheduleRecord.id;

  return (
      <AppointmentForm.CommandLayout
          { ...props}
          disableSaveButton={props.disableSaveButton}
          hideDeleteButton={hideDeleteButton}
          onCancelButtonClick={() => dispatch(setAddEditScheduleRecordForm(false))}
      />
  );
}
